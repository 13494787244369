export default {
	product: {
		name: 'Nom',
		shortName: 'Nom court',
		price: 'Prix',
		tva: 'TVA',
		type: 'Type',
		categories: 'Catégorie(s)',
		place: 'Lieu de fabrication',
		space: 'espace',
		assign: 'Assigner un produit',
		add: 'Ajouter',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer ce produit ?',
		deleteConfirm:
			'Êtes-vous sûr(e) de vouloir supprimer le produit "%{name}" ?',
		free_price: 'PRIX LIBRE',
		productTypeChange:
			'Vous avez changé le type de produit %{productName}, pensez à vérifier le lieu de fabrication et la TVA.',
		productTVAChange:
			'Vous avez changé le taux de TVA du produit %{productName}, pensez à vérifier le type du produit.',
		disalowAutoFillForPrice:
			'Action interdite sur le produit %{productName}, Vous ne pouvez pas copier le prix à partir de ce produit',
		productTVAAjusted:
			'La TVA a été changée automatiquement sur le produit %{productName}.',
		category_empty: 'Aucun produit associé à cette catégorie',
		nb_supplements: 'Nombre de suppléments',
		nb_prices: 'Nombre de catégories de prix',
		select_all: 'Tout sélectionner  / désélectionner',
		form: {
			supp: {
				min: 'Minimum',
				max: 'Maximum',
				required: 'Obligatoire',
			},
			name: 'Nom',
			shortName: 'Nom court (Facultatif)',
			description: 'Description (Facultatif)',
			price: 'Prix',
			price_default: 'Prix par défaut',
			tax: 'TVA',
			type: 'Type',
			category: 'Catégorie(s)',
			no_place: 'Aucun',
			place: 'Point de fabrication 1',
			place2: 'Point de fabrication 2 (Facultatif)',
			statusSend: "Temps d'envoi",
			visible: 'Visible',
			exclusion: 'Exclusions',
			direct: 'Direct',
			sku: 'SKU (Facultatif)',
			free_price: 'Prix libre',
			no_name: 'Sans titre',
			retail_price: 'Prix au détail',
			select: 'Sélectionnez...',
		},
		speedDial: {
			add_product: 'Nouveau produit (formulaire détaillé)',
			add_product_line: 'Nouveau produit (rapide)',
		},
		assign_form: {
			search: 'Chercher',
		},
		filter: {
			add_filter: 'Ajouter un filtre',
			color: 'Couleur',
			price: 'Prix',
			taxe_rate: 'TVA',
			product_type: 'Type',
			category: 'Catégorie',
			place_send: 'Lieu de fabrication',
			no_category: 'Sans catégorie',
			search: 'Rechercher...',
		},
		sort: {
			category_asc: 'Tri par ordre des catégories',
			category_desc: 'Tri par ordre inversé des catégories',
			product_asc: 'Nom de produit A-Z',
			product_desc: 'Nom de produit Z-A',
			price_asc: 'Prix croissant',
			price_desc: 'Prix décroissant',
		},
		types: {
			solid: 'Solide',
			alcool: 'Alcool',
			no_alcool: 'Non Alcool',
		},
	},
	url: {
		category: 'categorie',
		product: 'produit',
		tax: 'tax',
		supplement: 'supplement',
		placeSend: 'placeSend',
		product_menu: 'produitMenu',
		price_category: 'categoriePrix',
		floor: 'salle',
		category_display: 'Catégories',
		product_display: 'Produits',
		tax_display: 'TVA',
		supplement_display: 'Options Cuisine',
		placeSend_display: 'Fabrication',
		menu_display: 'Menus',
		price_category_display: 'Catégories de Prix',
		floor_display: 'Salles / Activités',
	},
	header: {
		update: 'Change the name of the changes',
		delete: 'Delete today\'s changes',
		menu: 'To the card manager',
		logout: 'Logout',
		reset: 'Deleting changes from %{date}',
		espaceClient: 'My Customer Area',
	},
	tax: {
		name: 'Nom',
		rates: 'Taux',
		rate: 'Taux sur place (%)',
		rate_take_away: 'Taux à emporter (%)',
		labelTaxRateError: 'Ce champ doit comporter un nombre entre 0 et 100.',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer cette TVA ?',
		default: 'Par défaut',
		active: 'Actif',
		editConfirm:
			'Attention : cette TVA est associée à %{count} produit(s), êtes-vous sûr(e) de vouloir la modifier ?',
	},
	category: {
		empty: 'Aucune catégorie de produits configurée',
		empty_exemple: 'Exemples : Entrées, Plats, Vins...',
		name: 'Nom',
		color: 'Couleur',
		icon: 'Icône',
		all: 'Tous les produits',
		confirm:
			"Êtes-vous sûr(e) de vouloir supprimer cette catégorie ? Les produits contenus dans la catégorie ne seront pas supprimés et seront toujours visibles dans 'Tous les produits'.",
		deleteConfirm: 'Confirmation de suppression',
		no_category: 'Sans catégorie',
		already_selected: 'Déja selectionné',
		product: {
			price: 'Prix à la carte',
			type: 'Type',
			tax: 'TVA',
			place_send: 'Lieu de fabrication',
			places_send: 'Lieux de fabrication',
			alert_price:
				'Vous ne pouvez pas rajouter des produits qui sont à 0.00%{currency} dans un menu.',
		},
	},
	supplementCategory: {
		empty: "Aucune catégorie d'options cuisine configurée",
		empty_exemple: ' Exemples : Cuisson, Parfums de glace...',
		form_name: "Nom de la catégorie d'options cuisine",
		name: 'nom',
		color: 'couleur',
		icon: 'icône',
		type1: 'Un seul choix',
		type2: 'Plusieurs choix',
		type3: 'Plusieurs choix en quantité',
		confirm:
			"Êtes-vous sûr(e) de vouloir supprimer cette catégorie d'options cuisine ?",
		associations: 'Voir les associations',
		min: 'min',
		max: 'max',
		labelMultiple:
			'Les articles associés régissent à la règle suivante (0 = falcultatif)',
		labelUnique: 'La saisie pour les articles associés est',
		add: "Ajouter catégorie d'options cuisine",
		changes_confirmation:
			"Voulez-vous appliquer les changements sur les produits liés à cette catégorie d'options cuisine",
	},
	supplement: {
		empty: 'Aucune option cuisine configurée',
		empty_exemple: 'Exemples : Vanille, Fraise, Saignant, A point ...',
		name: "Nom de l'option cuisine",
		price: 'Prix',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer cette option cuisine ?',
		add: 'Ajouter option cuisine',
		addSpace: 'Ajouter un espace',
	},
	generalButtons: {
		cancel: 'Annuler',
		ok: 'Ok',
		yes: 'Oui',
		no: 'Non',
		edit: 'Modifier',
		delete: 'Supprimer',
		reload: 'Reload',
	},
	user: {
		login: 'Se connecter',
		confirmLogout: 'Êtes-vous sûr de vouloir vous déconnecter ?',
	},
	productMenu: {
		name: 'Nom',
		shortName: 'Nom court',
		empty: 'Aucun menu configuré',
		price: 'Prix',
		tax: 'TVA',
		visible: 'Visible',
		confirmMenu: 'Êtes-vous sûr(e) de vouloir supprimer ce menu ?',
		confirmMenuLevel:
			'Êtes-vous sûr(e) de vouloir supprimer ce niveau de menu ?',
		nbMenuLevel: 'Niveaux',
		nbProduct: 'Produits',
		next: 'Suivant',
		back: 'Retour',
		levelName: 'Niveau de menu',
		excludedOn: 'Le menu est exclu pour :',
		title: 'Liste des menus',
		noProduct: 'Vous devez configurer un produit avant de configurer des menus',
		form: {
			titleCreation: "Création d'un menu",
			titleCreation2: 'Création du menu : ',
			name: 'Nom du menu',
			short_name: 'Nom court (Facultatif)',
			sku: 'SKU (Facultatif)',
			color: 'Couleur du Menu',
			description: 'Description (Facultatif)',
			errorName: 'Champs requis',
			errorPrice: 'Nombre positif requis',
			price: 'Prix',
			defaultPrice: 'Prix par défaut',
			step1: 'Dénomination',
			step2: 'Prix et Exclusions',
			step3: 'Gestion des produits',
			step4: 'Validation',
			submit: 'Valider',
			defaultMenuLevelName1: 'Entrée',
			defaultMenuLevelName2: 'Plat',
			is_alternative: 'Facultatif',
			add_menu_level: '+ Ajouter un niveau de menu',
			menu_level_form_name: 'Formulaire niveau de menu',
			product_menu_level_form_name: 'Formulaire Produit',
			active: 'Actif',
		},
	},
	priceCategory: {
		name: 'Nom',
		name_short: 'Nom court',
		time: 'Durée',
		days: 'Jours',
		floors: 'Salles',
		associations: 'Produit(s) associés',
		active: 'Actif',
		deleteConfirm:
			'Êtes vous sur de vouloir supprimer cette catégorie de prix ? Cette action est irréversible.',
		empty: 'Aucune catégorie de prix',
		empty_exemple: 'Exemples : Happy Hour, Terrasse ...',
		form: {
			name: 'Nom',
			name_short: 'Nom court (Facultatif)',
			time: 'Durée',
			days: 'Jours',
			days_label: 'Jours concernés par cette catégorie de prix',
			floors: 'Salles',
			floors_label: 'Salles concernées par cette catégorie de prix',
			active: 'Actif',
			start: 'Début',
			end: 'Fin',
			all_day: 'Toute la journée',
			display_settings: 'Affichage nom court',
			display_short_name_order: 'Affichage du nom court en commande',
			display_short_name_cashing: "Affichage du nom court à l'encaissement",
			display_short_name_manufacturing: 'Affichage du nom court en cuisine',
			associations: 'Produit(s) associé(s)',
			times: {
				from: 'De',
				to: 'à',
				tomorrow: 'le lendemain',
			},
			init: {
				button: 'Configuration rapide',
			},
			copy: {
				button: "Copier à partir d'une autre catégorie",
			},
			copyConfirmation:
				'Vous allez changer les prix de cette catégorie par ceux de la catégorie "%{categoryName}". Les prix de la catégorie actuelle seront remplacés. ',
			initConfirmation:
				'Vous allez configurer cette catégorie de prix, si des prix ont été précédemment renseignés, ils seront écrasés. Voulez-vous continuer ?  ',
		},
		init: {
			no_category:
				'Veuillez configurer des catégories et y associer des produits',
			no_other_price_category: "Il n'existe aucune autre catégorie de prix",
			none: 'Aucun changement',
			reinit: 'Réinitialiser (Suppression des prix)',
			price_fixed: 'Prix fixe',
			price_plus: 'Montant en plus',
			price_minus: 'Montant en moins',
			percent_plus: '% en plus',
			percent_minus: '% en moins',
		},
		popupAssociation: {
			type: 'Type',
			name: 'Nom',
			price: 'Prix',
			product: 'Produit',
			productMenu: 'Menu',
			supplement: 'Option cuisine',
			productInMenu: 'Produit Menu',
			empty:
				'Aucun(e) Produit / Menu / Option cuisine associé à cette catégorie de prix',
		},
	},
	floor: {
		no_configuration_here:
			"Les salles et activités doivent être configurées directement sur l'application IPad.",
		empty: 'Aucune salle configurée',
		no_floors: 'Aucune salle',
		all: 'Toutes les salles',
		name: 'Nom',
		type: 'Type',
		takeaway: 'Restauration rapide',
		counter_type: {
			stay_in: 'Sur place',
			take_away: 'A Emporter',
			delivery: 'Livraison',
		},
		form: {
			name: 'Nom',
			color: 'Couleur',
			category: 'catégorie',
			counter: 'Comptoir',
			general_options: 'Options générales',
			advanced_options: 'Options avancées',
			auto_order_closing: 'Fermeture auto',
			payment_after_ticket: 'Encaissement après ticket',
			flash_cashing: 'Encaissement flash',
			client: 'Ouverture automatique des clients',
			counter_type: 'Type',
			display_amount: 'Afficher le montant des commandes',
			print_auto_manufacturing_close:
				"Envoi automatique à la fermeture d'une commande",
			print_auto_manufacturing_exit:
				"Envoi automatique à la sortie d'une commande",
			print_auto_ticket_close_nb: 'Ticket(s) à imprimer',
			print_auto_ticket_close_cashed:
				'Afficher le montant encaissé sur le ticket',
			print_auto_ticket_close_status: 'Même si le ticket a déjà été imprimé',
		},
		exclusion_label: 'Interdire la sélection sur :',
	},
	days: {
		monday: 'Lundi',
		tuesday: 'Mardi',
		wednesday: 'Mercredi',
		thursday: 'Jeudi',
		friday: 'Vendredi',
		saturday: 'Samedi',
		sunday: 'Dimanche',
		EVERY_DAY: 'Tous les jours',
		no_days: 'Aucun jour',
	},
	retail: {
		type: {
			size: {
				title: 'Prix au mètre',
				unit: 'Mètre',
				subUnit: 'centimère',
			},
			time: {
				title: "Prix à l'heure",
				unit: 'Heure',
				subUnit: 'minute',
			},
			volume: {
				title: 'Prix au litre',
				unit: 'Litre',
				subUnit: 'centilitre',
			},
			weight: {
				title: 'Prix au Kg',
				unit: 'Kg',
				subUnit: 'gramme',
			},
		},
	},
	assignator: {
		tag_empty: 'Aucun produit sélectionné',
	},
	helper: {
		title: "Rubrique d'aide",
		button: "Besoin d'aide ?",
		close: 'Fermer',
		titles: {
			product: 'Produits',
			category: 'Catégories',
			supplement: 'Options Cuisine',
			menu: 'Menus',
			priceCategory: 'Catégories de prix',
			tax: 'TVA',
			quickstart: 'Par où commencer ?',
		},
		sections: {
			quickstart: {
				title: 'Par où commencer ?',
				paragraph_1:
					'Bienvenue sur votre outil de gestion de carte en ligne. Vous allez dès à présent pouvoir créer et personaliser la carte de votre établissement.',
				paragraph_2:
					'La "Carte Web" permet de créer tout les éléments de votre carte. Catégories, produits, options cuisines, menus...etc. ',
				paragraph_3:
					"Consultez les différentes sections de cette rubrique d'aide afin d'utiliser au mieu cet outil.",
			},
			product: {
				title: 'Produits',
				create: {
					title: 'Créer un nouveau produit',
					paragraph_1:
						"Pour ajouter un nouveau produit, cliquez sur la croix rouge « + ». Deux icônes s'affichent alors de part et d'autre de cette croix :",
					fast: {
						title: '"Nouveau produit (rapide)" ',
						paragraph_1:
							"(à gauche de la croix rouge) : permet d'ajouter une ligne en bas de la grille afin de créer rapidement un produit. Dans cette nouvelle ligne, vous pouvez saisir les informations du produit en cliquant directement dans chaque champ.",
						paragraph_2:
							"Si vous souhaitez accéder au formulaire détaillé du produit, cliquez sur l'icône de modification (picto crayon) en fin de ligne.",
						paragraph_3:
							'Dans le cas où plusieurs de vos produits ont le même prix, TVA, Type, Catégorie et/ou Lieu de fabrication, une astuce vous permet de simplifier le renseignement des champs, pour vous éviter de remplir chaque champ un à un.',
						paragraph_4:
							'Pour cela, créez vos produits en « Nouveau produit (rapide) ». Renseignez le nom de chacun, et renseignez le prix du premier produit. Puis cliquez sur le champ « Prix » de ce produit. Le champ s’entoure d’un cadre bleu avec un petit carré bleu dans l’angle en bas à droite. Avec votre souris, faites glisser ce petit carré sur les autres lignes de produit : le prix s’appliquera alors à tous les produits concernés.',
						paragraph_5:
							'Vous pouvez faire de même avec la TVA, le type, la catégorie et le lieu de fabrication.',
					},
					form: {
						title: '"Nouveau produit (formulaire détaillé)" ',
						content:
							"(à droite de la croix rouge) : permet d'accéder au formulaire détaillé de création de produit.",
						list: {
							name: {
								title: 'Nom : ',
								content:
									"(champ obligatoire) Libellé du produit utilisé par défaut dans l'application.",
							},
							short_name: {
								title: 'Nom court : ',
								content:
									'Libellé du produit utilisé, si l’option est activée dans la grille de produits durant la prise de commande et/ou sur les impressions (en cuisine et le ticket de caisse).',
							},
							sku: {
								title: 'SKU : ',
								content:
									'Si vous avez plusieurs établissements et des plats communs mais qui n’ont pas le même nom, entrez un code commun pour ce plat.',
							},
							description: {
								title: 'Description : ',
								content:
									'Description ou commentaire du plat. Accessible lors de la prise de commande.',
							},
							price: {
								title: 'Prix : ',
								content: 'Prix TTC du produit.',
								paragraph_1:
									'Le prix peut aussi être « Libre », c’est-à-dire que l’application vous demandera de renseigner le prix souhaité lors de la commande du produit.',
								paragraph_2:
									"Le prix peut aussi être « au détail ». Pour cela cochez la coche « prix au détail » et sélectionnez l'unité de mesure (prix au kg, au mètre, au litre ou à l'heure) et renseignez le prix.",
								paragraph_3:
									"Si vous définissez des catégories de prix (ex : Happy hour), dans la rubrique « Catégorie de prix », vous pourrez choisir ici d'attribuer ou non la catégorie de prix à ce produit et de définir le prix pour le produit en question.",
							},
							visible: {
								title: 'Visible : ',
								content:
									'Rendre le produit visible ou non dans la prise de commande (en cas de produit non disponible temporairement ou hors saison) sans le supprimer de vos réglages de carte.',
							},
							vat: {
								title: 'TVA : ',
								content:
									'(champ obligatoire) Sélectionner le taux de TVA à appliquer sur ce produit.',
								paragraph:
									'Trois TVA sont pré-configurées. La TVA par défaut attribuée à tout nouveau produit est 10%. Vous pouvez configurer de nouveaux taux dans la rubrique « TVA ».',
							},
							type: {
								title: 'Type : ',
								content:
									'(champ obligatoire) Le type de produit est utilisé pour faire des rapports au sein de votre ou vos établissements (disponible dans le reporting en ligne). La liste des types de produits est prédéfinie. Lorsque vous cliquez sur le champ « Type », vous verrez apparaître la liste de tous les types possibles, avec l’information suivante : Solide, Alcool ou Non Alcool.',
							},
							category: {
								title: 'Catégorie : ',
								content:
									'Permet de classer le produit dans une ou plusieurs catégories de produit que vous avez créées dans la rubrique « Catégorie » (vous pouvez cocher plusieurs catégories).',
							},
							place_send_1: {
								title: 'Point de fabrication 1 : ',
								content:
									"Lieu d'envoi du produit (lieu où sera imprimé le ticket de fabrication). Par défaut, le point de fabrication pour tout nouveau produit est « Cuisine ».",
							},
							place_send_2: {
								title: 'Point de fabrication 2 : ',
								content:
									"Lieu d'envoi secondaire du produit (second lieu où sera envoyé le ticket de fabrication).",
							},
							status_send_index: {
								title: "Temps d'envoi : ",
								content:
									"Temps d'envoi du produit (Direct, Suite 1, Suite 2, Suite 3...). Par défaut, le temps d'envoi est fixé à « Direct » pour tout nouveau produit.",
							},
							supplements: {
								title: 'Suppléments : ',
								content:
									"Ici s'affichent les options de cuisine créées dans la rubrique « Options cuisine ».",
								paragraph_1:
									"En cochant une ou plusieurs catégories de suppléments, un menu déroulant apparaitra sur la droite dans l'application lors de la prise de commande pour indiquer au serveur de renseigner les suppléments (par exemple ➔ plat : Entrecôte, suppléments : Cuisson, Accompagnement et/ou Sauce).",
								paragraph_2:
									'Ces suppléments peuvent être obligatoires ou facultatifs, ou avec un min/max pour les options permettant de sélectionner plusieurs choix (voir la section « Options cuisine » pour plus d’informations).',
							},
							color: {
								title: 'Couleur : ',
								content:
									'Couleur de la touche du produit dans la grille de prise de commande.',
							},
							exclusions: {
								title: 'Exclusions : ',
								content:
									'Cette fonctionnalité permet de choisir les salles dans lesquelles le produit n’apparaîtra pas sur la grille de commande.',
							},
						},
						paragraph_1_bold:
							'Pour enregistrer votre produit, cliquez sur le bouton bleu « Enregistrer » ',
						paragraph_1:
							"en haut à droite du formulaire. Si ce bouton est grisé, cela signifie qu'un des 3 champs obligatoires (nom, TVA et type) n'est pas renseigné.",
						paragraph_2:
							"Si vous souhaitez qu'un produit soit disponible dans un menu mais non visible à la carte, créez une catégorie « Menu » dans laquelle vous classerez tous les produits de vos menus et excluez cette catégorie de toutes les salles. Une fois l'application installée, il vous suffira d'aller dans « Réglages / Options » et de cocher « Autoriser la sélection d'un produit non visible dans un menu ».",
					},
				},
				update: {
					title: 'Modifier un produit',
					paragraph_1:
						"Au sein du tableau de produits, vous pouvez modifier des informations d'un produit à la volée, en cliquant directement sur le champ d'un produit que vous souhaitez modifier dans la grille. Exemple : modification de couleur, nom, catégories...",
					paragraph_2:
						"Pour accéder au formulaire détaillé, cliquez sur l'icône de modification (picto crayon) en fin de ligne.",
				},
				cancel_update: {
					title: 'Annuler une modification',
					paragraph:
						"En haut à droite du tableau de l'ensemble des produits, figurent 2 flèches. Ces flèches permettent d'annuler la dernière modification faite (flèche vers la gauche) ou bien de revenir à une modification annulée (flèche vers la droite).",
				},
				reweight: {
					title: 'Organiser les produits dans la grille de commande',
					paragraph:
						"Les catégories de produits apparaissent en liste à gauche du tableau des produits. Cliquez sur une des catégories pour afficher uniquement les produits associés à une catégorie. Vous pouvez ainsi voir la façon dont s'afficheront les produits dans la grille de commande sur l'application.",
					list: {
						item_1:
							"Pour les réorganiser dans la grille, cliquez sur un des produits et faites-le glisser à l'endroit souhaité.",
						item_2:
							"Pour ajouter des espaces, cliquez sur le bloc gris « espace » et faites-le glisser à l'endroit souhaité. Les « espaces » sont des espaces vides dans le cas où vous voudriez fait une mise en forme spécifique. Vous pouvez en intégrer autant que voulu.",
						item_3_1:
							"Pour ajouter un produit, cliquez sur le bloc gris « Assigner un produit » et faites-le glisser à l'endroit souhaité.",
						item_3_2:
							"Une fenêtre s'ouvre. Sélectionnez le ou les produits que vous souhaitez ajouter en cochant la case à droite de la ligne de produit. Le champ « chercher » vous permet de rechercher facilement un produit par son nom. Vous pouvez aussi chercher un produit dans une des catégories sur la gauche.",
						item_3_3:
							"Cliquez sur le bouton « Enregistrer » en haut à droite du formulaire. Le ou les produits ajoutés apparaissent alors en bas de votre grille. Vous pouvez le déplacer à l'endroit que vous souhaitez.",
						item_4:
							"Pour supprimer un produit d'une catégorie, passer la souris sur le bloc produit à supprimer et cliquez sur l'icône rouge de suppression qui apparaît en haut à droite du bloc. Le produit sera conservé dans la liste globale des produits mais n'appartiendra plus à aucune catégorie.",
						item_5:
							"Pour modifier un produit, cliquez sur le bloc du produit en question afin d'afficher le formulaire de modification.",
					},
				},
				filters: {
					title: 'Filtres',
					content:
						'Vous pouvez filtrer la liste des produits créés. Pour cela, plusieurs filtres sont proposés :',
					list: {
						search: {
							title: 'Rechercher : ',
							content: 'effectuer une recherche par nom de produit',
						},
						sort: {
							title: 'Tri : ',
							content:
								'trier par ordre des catégories, par ordre alphabétique de nom de produits ou bien par prix.',
						},
						add_filter: {
							title: 'Ajouter un filtre : ',
							content:
								'afficher uniquement les produits correspondant à une couleur, un prix, une TVA, un type, une catégorie ou bien un lieu de fabrication',
						},
					},
				},
			},
			category: {
				title: 'Catégories',
				paragraph_1:
					'Les catégories permettent d’organiser la carte en regroupant des plats ou des boissons selon la logique que vous souhaitez. Cette section permet de gérer les catégories dans lesquelles seront classés vos produits.',
				paragraph_2:
					'Pour créer une nouvelle catégorie, cliquez sur la croix rouge « + ».',
				paragraph_3:
					'Dans le formulaire à droite, renseignez le nom de la catégorie, sa couleur, son icône et éventuellement la ou les salles à exclure dans lesquelles cette catégorie ne sera pas proposée sur la grille de commande, puis cliquez sur « Enregistrer » en haut à droite du formulaire.',
				paragraph_4:
					"Vous pouvez réorganiser l'ordre des catégories créées. Pour cela, sélectionnez une des catégories en cliquant sur l'icône avec les 3 traits (à gauche de l'intitulé de la catégorie) et déplacez-la en la faisant glisser à l'endroit souhaité dans la liste.",
				paragraph_5:
					'Pour modifier une catégorie, cliquez sur celle que vous souhaitez modifier et changez les informations dans le formulaire à droite.',
				paragraph_6:
					"Pour supprimer une catégorie, cliquez sur l'icône « supprimer » en fin de ligne. Si des produits sont associés à la catégorie supprimée, les produits seront conservés mais n'appartiendront plus à aucune catégorie.",
				paragraph_7:
					"Si vous souhaitez qu'un produit soit disponible dans un menu mais non visible à la carte, créez une catégorie « Menu » dans laquelle vous classerez tous les produits de vos menus et excluez cette catégorie de toutes les salles. Une fois l'application installée, il vous suffira d'aller dans « Réglages / Options » et de cocher « Autoriser la sélection d'un produit non visible dans un menu ».",
			},
			supplement: {
				title: 'Options Cuisine',
				paragraph_1:
					"Cette section regroupe tout ce qui peut être ajouté (gratuitement ou non, et obligatoirement ou non) à un produit. Ces suppléments sont regroupés par catégories d'options cuisine (ex : Cuisson) et des options cuisine peuvent être associées à chacune de ces catégories (ex : saignant, à point...).",
				create: {
					title: "Créer une catégorie d'options cuisine",
					paragraph_1:
						"Pour créer une catégorie d'option de cuisine, cliquez sur le bouton \"Ajouter une catégorie d'option cuisine\". Le formulaire s'ouvre alors.",
					list: {
						name: {
							title: "Nom de la catégorie d'options cuisine : ",
							content:
								"Libellé de la catégorie d'option. Privilégiez un nom court.",
						},
						required: {
							title: 'Obligatoire : ',
							content:
								"cochez cette coche si vous souhaitez que l'option soit obligatoire lorsqu'elle sera attribuée à un produit. Si un supplément est paramétré comme étant obligatoire, le serveur ne pourra pas envoyer la commande en fabrication tant qu’il n’aura pas saisi le nombre de suppléments attendus.",
							paragraph:
								"Vous pouvez laisser cette option décochée par défaut et la cocher plus tard au cas par cas dans le formulaire des produits auxquels vous l'associerez. Ainsi, cette option pourra être obligatoire pour certains produits, et facultative pour d'autres.",
						},
						type: {
							title: 'Type : ',
							content: 'Il existe 3 types de suppléments : ',
							list: {
								unique: {
									title: 'Un seul choix ',
									content:
										'permet de sélectionner un seul supplément lors de la commande : une cuisson pour une viande par exemple.',
								},
								multiple: {
									title: 'Plusieurs choix ',
									content:
										'permet de sélectionner plusieurs suppléments mais en quantité unique : le client désire une salade et des frites en accompagnement par exemple.',
								},
								multiple_quantity: {
									title: 'Plusieurs choix en quantité ',
									content:
										'permet de sélectionner plusieurs suppléments en quantité X : le client désire 2 boules de glace vanille et 1 boule de glace chocolat, vous pourrez donc cliquer deux fois sur vanille et une fois sur chocolat.',
								},
							},
							description: {
								paragraph_1:
									'En fonction du type de supplément, le caractère obligatoire ou non se présentera différemment :',
								list: {
									unique: {
										title: '« Un seul choix » : ',
										content:
											'si l’option « Obligatoire » est activée, le serveur devra saisir un seul supplément dans la commande. Sinon, la saisie du supplément est facultative.',
									},
									multiple: {
										title:
											'« Plusieurs choix » ou « Plusieurs choix en quantité » : ',
										content:
											'pour ces deux types, il est possible de paramétrer un nombre minimum et maximum de supplément(s) à saisir. ',
									},
								},
								paragraph_2:
									'Par exemple pour une glace avec 3 boules obligatoires, il faudra indiquer min : 3 / max 3. Si le nombre est "0", la saisie minimum et/ou maximum devient facultative, le serveur pourra sélectionner autant de boules de glace qu\'il souhaite, ou bien aucune.',
								paragraph_3:
									'Vous pouvez laisser min 0 et max 0 par défaut et le préciser plus tard au cas par cas dans le formulaire des produits auxquels vous associerez cette option. Ainsi, cette option pourra avoir des min/max différents pour chaque produit.',
							},
						},
						icon_color: {
							title: 'Icône et couleur : ',
							content:
								"Choisissez l'icône et la couleur qui représentera la catégorie d'options cuisine dans la prise de commande.",
						},
						exclusions: {
							title: 'Exclusions : ',
							content:
								"Cette fonctionnalité permet de choisir les salles dans lesquelles la catégorie d'options cuisine n’apparaîtra pas lors de la commande. ",
						},
					},
					paragraph_2:
						"Pour modifier une catégorie d'options cuisine créée, cliquez sur l’icône de modification (le crayon).",
				},
				reweight: {
					title: 'Ordre des options de cuisine',
					paragraph:
						"Vous pouvez réorganiser l'ordre des catégories d'options créées. Pour cela, sélectionnez une des catégories en cliquant sur l'icône avec les 3 traits (à gauche de l'intitulé de la catégorie) et déplacez-la vers le haut ou le bas en la faisant glisser à l'endroit souhaité dans la liste.",
				},
				supplements: {
					title: "Définir les options cuisine d'une catégorie d'options",
					paragraph_1:
						"Vous pouvez ajouter autant d'options cuisine que vous souhaitez à une catégorie d'option.",
					paragraph_2:
						"Pour cela, sélectionnez la catégorie d'options cuisine (cliquez sur la ligne de l'intitulé de la catégorie) et cliquez sur « Ajouter option cuisine » dans la partie droite. Dans le formulaire qui apparait, indiquez le nom de l'option, sa couleur, les salles éventuelles à exclure et enregistrez.",
					paragraph_3:
						"Pour une option payante, saisissez le montant du supplément (montant qui s'ajoutera au montant initial du produit).",
					paragraph_4:
						"Les options cuisine sont présentées en grille de façon similaire à celles que vous retrouverez sur la prise de commande de l'application. Vous pouvez déplacer chaque bloc en cliquant dessus et en le faisant glisser à l'endroit souhaité.",
					paragraph_5:
						'Pour ajouter un espace, cliquez sur le bouton « ajouter un espace ». Les "ESPACES" sont des espaces vides dans le cas ou vous voudriez faire une mise en forme spécifique.',
				},
				associate: {
					title: "Attribuer une catégorie d'options cuisine à un produit",
					paragraph:
						"Il existe 2 façons d'attribuer une catégorie d'options à des produits :",
					list: {
						item_1:
							"1 / Cliquez sur l'icône « Assigner des produits » (bloc note) et cochez les produits auxquels vous souhaitez associer cette option.",
						item_2:
							"2 / Vous pouvez également associer l'option cuisine directement depuis la section « Supplément » au sein du formulaire du produit souhaité (dans la rubrique « Produits »).",
					},
				},
			},
			menu: {
				title: 'Menus',
				paragraph:
					"Pour ajouter un nouveau menu, cliquez sur l'icône rouge « + ». La création d'un menu s'effectue en 4 étapes. A la fin de chaque étape, cliquez sur « suivant » pour passer à l'étape suivante :",
				denomination: {
					title: '1 / Dénomination',
					list: {
						name: {
							title: 'Nom du menu : ',
							content:
								"(champ obligatoire) Libellé du menu utilisé par défaut dans l'application",
						},
						short_name: {
							title: 'Nom court :  ',
							content:
								'Libellé du menu utilisé si l’option est activée dans la grille de produits durant la prise de commande et/ou sur les impressions (en cuisine et le ticket de caisse).',
						},
						sku: {
							title: 'SKU : ',
							content:
								'Si vous avez plusieurs établissements et des menus communs mais qui n’ont pas le même nom, entrez un code commun pour ce menu.',
						},
						description: {
							title: 'Description : ',
							content:
								'Description ou commentaire du menu. Accessible lors de la prise de commande.',
						},
						active: {
							title: 'Actif : ',
							content:
								'Rendre le menu visible ou non dans la prise de commande (en cas de menu non disponible temporairement ou saisonnier)',
						},
						color: {
							title: 'Couleur du Menu : ',
							content:
								'Couleur de la touche du menu dans la grille de prise de commande.',
						},
					},
				},
				prices_exclusions: {
					title: '2 / Prix et exclusions',
					list: {
						exclusions: {
							title: 'Exclusions : ',
							content:
								'Cette fonctionnalité permet de choisir les salles dans lesquelles le produit n’apparaîtra pas sur la grille de commande.',
						},
						prices: {
							title: 'Prix :  ',
							content: 'Prix TTC du Menu',
							paragraph:
								"Si vous définissez des catégories de prix (ex : Happy hour), dans la rubrique « Catégories de prix », les catégories de prix apparaissent sous le prix TTC du menu. Vous pouvez choisir d'attribuer ou non la catégorie de prix à ce menu et de définir le prix pour le menu en question.",
						},
					},
				},
				products: {
					title: '3 / Gestion des produits',
					paragraph_1:
						'Cette étape vous permet de définir les différents niveaux du menu et les produits attribués à chaque niveau.',
					paragraph_2:
						'Par défaut, 2 niveaux sont prédéfinis : « Entrée » et « Plat ».',
					list: {
						item_1:
							"Pour ajouter un nouveau niveau, cliquez sur « Ajouter un niveau de menu ». Renseignez le nom du niveau (ex : Dessert ») et l'icône associée, et enregistrez.",
						item_2_1:
							'Pour modifier leur intitulé et leur attribuer une icône, cliquez sur le picto de modification (le crayon).',
						item_2_2:
							'Vous pouvez rendre un niveau de menu « facultatif » en cochant la coche « Facultatif ». Lors de la prise de commande, le menu pourra être envoyé sans sélectionner de produit dans ce niveau.',
						item_2_3:
							'Par exemple pour un menu Entrée/Plat et Plat/dessert au même prix, vous pouvez créer 3 niveaux de menu (Entrée/plat/dessert) et rendre les niveaux « Entrée » et « dessert » facultatifs.',
						item_3:
							"Pour supprimer un niveau, cliquez sur la croix près de l'intitulé du niveau de menu.",
						item_4:
							"Pour réorganiser les niveaux, sélectionnez un des niveaux et déplacez-le vers le haut ou vers le bas à l'endroit souhaité.",
						item_5:
							"Pour ajouter des produits à un niveau, cliquez sur le bloc gris « + produit ». Une fenêtre s'ouvre alors. Pour sélectionner le ou les produits que vous souhaitez ajouter, cochez la case à droite de la ligne de produit. Le champ « Chercher » vous permet de rechercher facilement un produit par son nom. Vous pouvez aussi chercher un produit dans une des catégories sur la gauche.",
					},
					paragraph_3:
						'Cliquez sur le bouton « Enregistrer » en haut à droite du formulaire.',
					paragraph_4:
						'Le ou les produits ajoutés apparaissent alors dans la grille du niveau de menu.',
					paragraph_5:
						"Vous pouvez réorganiser leur ordre : pour cela, cliquez sur un produit et déplacez-le à l'endroit souhaité.",
				},
				validation: {
					title: '4 / Validation',
					content:
						'Cette étape vous permet de vérifier les informations du menu avant de valider.',
				},
			},
			priceCategory: {
				title: 'Catégories de prix (Multiprix)',
				paragraph:
					'Les catégories de prix servent à attribuer plusieurs prix à un même produit afin de gérer par exemple des happy hours, des prix à emporter ou des prix terrasse. Vous pouvez en effet créer autant de prix que nécessaire !',
				create: {
					title: 'Créer une catégorie de prix',
					paragraph_1:
						'Pour créer une nouvelle catégorie, cliquez sur la croix « + » rouge pour afficher le formulaire.',
					list: {
						title: 'Remplissez les champs suivants :',
						name: {
							title: 'Nom : ',
							content:
								'Libellé de la catégorie de prix utilisé par défaut dans l’application.',
						},
						short_name: {
							title: 'Nom court : ',
							content:
								'Libellé court de la catégorie de prix, qui s’affichera à côté du nom du produit / menu / supplément aux endroits listés ci-dessous, si les options correspondantes sont activées :',
							list: {
								item_1:
									'Affichage du nom court en commande : le nom court de la catégorie s’affichera dans la grille de commande',
								item_2:
									'Affichage du nom court à l’encaissement : le nom court de la catégorie s’affichera dans l’écran d’encaissement et également sur les tickets de caisse',
								item_3:
									'Affichage du nom court en fabrication : le nom court de la catégorie s’affichera sur les bons de fabrication ',
							},
						},
						active: {
							title: 'Actif : ',
							content:
								'Cet interrupteur sert à activer ou désactiver la catégorie de prix, pour pouvoir la réutiliser ultérieurement sans la supprimer.',
						},
						duration: {
							title: 'Durée : ',
							content:
								' « Journée entière ou horaires » : Si la catégorie de prix doit être utilisable toute la journée, laissez l’interrupteur « Journée entière » activé, sinon désactivez-le pour accéder aux horaires, et définir une heure de début ainsi qu’une heure de fin de validité de la catégorie. L’heure de fin peut très bien être définie le lendemain, par exemple de 19:00 à 02:00 le lendemain.',
						},
						days: {
							title: 'Jours : ',
							content:
								'Ce champ sert à sélectionner les jours pendant lesquels la catégorie de prix sera active. S’il n’y a pas de distinction à faire, laisser «Tous les jours».',
						},
						floors: {
							title: 'Salles : ',
							content:
								'Ce champ sert à sélectionner les salles dans lesquelles la catégorie de prix sera active. S’il n’y a pas de distinction à faire, laissez toutes les salles cochées.',
						},
					},
					paragraph_2:
						'Cliquez sur « Enregistrer » une fois les paramètres définis.',
				},
				config: {
					title: 'Configurer des prix',
					paragraph_1:
						'Il existe trois façons de configurer les prix de votre catégorie de prix :',
					list: {
						item_1: 'Configuration rapide',
						item_2: 'Copie intégrale de catégorie de prix',
						item_3: 'Configuration manuelle produit par produit',
					},
					paragraph_2_1:
						'Lorsque vous avez créé votre catégorie de prix, cliquez sur la catégorie créée pour afficher de nouveau le formulaire : les boutons ',
					paragraph_2_2: "« Copier à partir d'une autre catégorie »",
					paragraph_2_3: ' et ',
					paragraph_2_4: '« Configuration rapide » ',
					paragraph_2_5: 'sont devenus actifs.',
					fast: {
						title: '1 / Configuration rapide : ',
						paragraph_1:
							'Le bouton « Configuration rapide » sert à paramétrer rapidement des prix sur les produits de votre carte (uniquement les produits, et non pas les menus ou suppléments).',
						paragraph_2:
							'Cliquer sur le bouton pour faire apparaître la fenêtre de configuration rapide.',
						paragraph_3:
							'Dans cette fenêtre sont listées toutes les catégories de produits de votre carte.',
						paragraph_4:
							'Pour chaque catégorie souhaitée, sélectionnez un des calculs disponibles pour paramétrer les prix liés à la catégorie de prix :',
						list: {
							no_changes: {
								title: 'Aucun changement : ',
								content:
									'laissez ainsi si vous ne souhaitez pas appliquer la catégorie de prix à cette catégorie de produit.',
							},
							fixed_price: {
								title: 'Prix fixe : ',
								content:
									'appliquer le même prix sur tous les produits des catégories sélectionnées.',
							},
							price_plus: {
								title: 'Montant en plus : ',
								content:
									'ajouter un montant déterminé au prix par défaut de chacun des produits des catégories sélectionnées (par exemple 0,20€) ',
							},
							price_minus: {
								title: 'Montant en moins : ',
								content:
									'enlever un montant déterminé au prix par défaut de chacun des produits des catégories sélectionnées',
							},
							percent_plus: {
								title: '% en plus : ',
								content:
									'ajouter un pourcentage au prix par défaut de chacun des produits des catégories sélectionnées (par exemple 50%) ',
							},
							percent_minus: {
								title: '% en moins : ',
								content:
									'enlever un pourcentage au prix par défaut de chacun des produits des catégories sélectionnées',
							},
							reinit: {
								title: 'Réinitialiser : ',
								content:
									'cette fonctionnalité permet de supprimer les paramètres de catégorie de prix  sur tous les produits des catégories sélectionnées.',
							},
						},
						paragraph_5:
							'Sélectionnez un des choix, renseignez un montant ou un pourcentage si besoin, puis validez. Vous pouvez définir des règles différentes selon vos catégories de produits.',
						paragraph_6:
							'Après avoir cliqué sur « Valider », une fenêtre s’affiche pour confirmer le paramétrage des prix. Cliquez sur « Oui » pour confirmer. ',
						paragraph_7:
							'Pour vérifier que les prix de la catégorie de prix ont bien été sauvegardés,  cliquez sur le picto gris « i » sur la ligne de la catégorie de prix. Vous verrez apparaître à côté de chaque produit deux prix : le prix par défaut, ainsi que le prix de la catégorie prix qui vient d’être paramétré.',
					},
					integral: {
						title: '2 / Copie intégrale :',
						paragraph_1_1: 'Le bouton ',
						paragraph_1_2: "« Copier à partir d'une autre catégorie » ",
						paragraph_1_3:
							"sert à copier l'intégralité des prix déjà paramétrés d'une catégorie de prix (produits, menus, suppléments) vers une autre.",
						paragraph_2:
							"Pour ce faire, après avoir créé une catégorie de prix, affichez de nouveau le formulaire de la catégorie de prix et cliquez sur le bouton « Copier à partir d'une autre catégorie ». Une fenêtre s’affiche avec la liste des catégories de prix déjà existantes. Cliquez sur la catégorie dont vous voulez copier l’intégralité des prix, puis confirmez la copie.",
						paragraph_3:
							'Pour vérifier que la copie des prix de la catégorie de prix a bien eu lieu, même principe que pour la configuration rapide : cliquez sur le picto gris « i » sur la ligne de la catégorie de prix. Vous verrez apparaître à côté de chaque produit deux prix : le prix par défaut, ainsi que le prix de la catégorie prix qui vient d’être paramétré.',
					},
					by_product: {
						title:
							'3 / Configuration manuelle par produit / menu / supplément :',
						paragraph_1:
							'Pour configurer les prix d’une catégorie de prix sur chacun des produits ou menus, rendez-vous dans la rubrique « Produits » ou « Menus ».',
						paragraph_2:
							"La ou les catégories de prix que vous avez créées apparaissent dans la section « Prix » dans le formulaire d'un produit ou d'un menu.",
						paragraph_3:
							'Cochez la catégorie de prix que vous souhaitez activer et indiquez son prix.',
						paragraph_4:
							"Pour les suppléments,  rendez-vous dans la rubrique « Options cuisine ». Sélectionnez la catégorie d'option de votre choix et cliquez sur une des options associées. La ou les catégories de prix que vous avez créées apparaissent dans la section « Prix ».",
					},
				},
			},
			tax: {
				title: 'TVA',
				paragraph_1_1: 'Trois TVA sont pré-configurées : ',
				paragraph_1_2: '10% ',
				paragraph_1_3: 'pour la nourriture et les boissons non alcoolisées, ',
				paragraph_1_4: ' 10% / 5,5% ',
				paragraph_1_5:
					'pour gérer les produits vendus « sur place » et « à emporter » et qui sont assujettis à la taxe 5,5%, et enfin ',
				paragraph_1_6: '20% ',
				paragraph_1_7: 'pour les produits alcoolisés.',
				paragraph_2:
					"Il est possible de modifier ces TVA en cliquant dessus et en modifiant le formulaire qui s'affiche sur le côté droit.",
				note: {
					title: 'A noter : ',
					content_1:
						'si on change un taux, tous les produits qui sont liés à ce taux seront ',
					content_2: 'impactés.',
				},
				paragraph_3:
					"La TVA à 10% est attribuée par défaut lors de la création d'un nouveau produit. Pour définir une autre TVA par défaut, cliquez sur une des TVA, cochez « par défaut » dans la partie de droite et enregistrez.",
				create: {
					title: 'Créer une nouvelle TVA :',
					paragraph_1:
						'Pour ajouter une nouvelle TVA, cliquez sur le picto rouge « + » et remplissez les champs présents sur le côté droit.',
					paragraph_2:
						'Pour une même TVA il est possible de configurer des taux différents pour le service sur place et à emporter.',
				},
				delete: {
					title: 'Supprimer une TVA :',
					paragraph_1: 'Seules les TVA non actives peuvent être supprimées',
					paragraph_2:
						"Pour supprimer une TVA, il faut donc au préalable réaffecter l’ensemble des produits attribués à cette TVA, puis la rendre inactive (pour cela cliquez sur l'intitulé de la TVA et décochez « actif »).",
					paragraph_3:
						'Une fois définie comme inactive, un picto de suppression apparaît sur la ligne de la TVA. Cliquez dessus pour la supprimer.',
					note: {
						title: 'A noter : ',
						content:
							'une TVA définie « par défaut » ne peut pas être supprimée.',
					},
					paragraph_4:
						'Si un doute subsiste sur le taux de TVA à appliquer sur certains produits, il est recommandé de vous rapprocher de votre comptable qui pourra indiquer le bon taux à appliquer. ',
				},
			},
		},
	},
	save: 'Enregistrer',
	confirmation: 'Confirmation',
	validate: 'Valider',
	cancel: 'Annuler',
	remove: 'Supprimer',
	edit: 'Modifier',
	close: 'Fermer',
	notification: {
		title: 'Notifications',
		show: 'Voir',
		empty: 'Aucune notification',
		error: {
			product:
				'Une erreur est survenue lors de la %{method} du produit: %{name}',
			category:
				'Une erreur est survenue lors de la %{method} de la catégorie: %{name}',
			priceCategory:
				'Une erreur est survenue lors de la %{method} de la catégorie de prix: %{name}',
		},
		toast: {
			warning: 'Une situation mérite votre attention',
			error: 'Il y a eu des erreurs',
			forbidden: 'Accès refusé',
			unauthorized:
				"Vous n'avez pas les droits suffisant pour effectuer cette action",
		},
		type: {
			WARNING: 'Attention',
			ERROR: 'Erreur',
			INFO: 'Info',
			SUCCESS: 'Succès',
		},
		no_detail: 'Pas de détail supplémentaire',
	},
	method: {
		PATCH: 'mise à jour',
		PUT: 'mise à jour',
		POST: 'création',
		DELETE: 'suppression',
	},
	no_access: "Vous n'avez pas accés à l'outil de gestion de la carte en ligne",
	no_access_in_synchro:
		'Une synchronisation est en cours sur la carte en ligne. Veuillez réessayer dans quelques instants',
	no_access_explained:
		"Vous n'avez pas accés à l'outil de gestion de la carte en ligne. Vous avez surement déjà configurer votre Carte, pour plus de renseignement veuillez contacter le support.",
	browser_compatibility: {
		version_title: 'Navigateur obsolète',
		version_label:
			'Afin de vous offrir une experience optimale, veuillez passer sur la dernière version de Google Chrome ou Firefox',
		chrome: 'Google Chrome',
		firefox: 'Mozilla Firefox',
		screen_size_title: 'Votre écran est trop petit',
		screen_size_label:
			'Afin de vous offrir une experience optimale, veuillez passer sur un ordinateur avec un ecran plus grand.',
	},
	icon: {
		search: 'Chercher une icône',
	},
	title: {
		denomination: 'Dénomination',
		informations: 'Informations',
		price: 'Prix',
		supplements: 'Suppléments',
		exclusions: 'Exclusions',
		color: 'Couleur',
		icon: 'Icône',
		floors: 'Salles',
	},
};
