export default {
	product: {
		name: 'Nombre',
		shortName: 'Abreviatura',
		price: 'Precio',
		tva: 'IVA',
		type: 'Tipo',
		categories: 'Categoría/s',
		place: 'Punto de elaboració',
		space: 'Espacio',
		assign: 'Añadir productos',
		add: 'Añadir',
		confirm: '¿Seguro que quieres eliminar este producto?',
		deleteConfirm: '¿Seguro que quieres eliminar este producto "%{name}" ?',
		free_price: 'PRECIO LIBRE',
		productInCreation:
			'Por favor, registre su nuevo producto: %{productName} antes de modificar un producto existente',
		productTypeChange:
			'Has modificado el producto %{productName}, por favor, verifica el punto de elaboración y la tasa de IVA.',
		productTVAChange:
			'Has modificado la tasa de IVA %{productName}, por favor, verifica el tipo de producto.',
		disalowAutoFillForPrice:
			'Acción no disponible: no se puede copiar el precio del producto %{productName}',
		disalowForFreePrice:
			'Cambio no aplicado del producto %{productName}. Esta acción está prohibida en los precios libres.',
		disalowForProductVolume:
			'No puede copiar el precio en productos con un precio especial o un precio libre.',
		productTVAAjusted:
			'El IVA de este producto ha sido modificado automáticamente %{productName}.',
		skuRequired: 'El campo SKU es obligatorio',
		skuIntRequired: 'El campo SKU es obligatorio y debe ser un número entero.',
		category_empty: 'No hay productos en esta categoria',
		nb_supplements: 'Número de suppléments',
		nb_prices: 'Nombre de categoría de precios',
		select_all: 'Seleccionar / deseleccionar',
		wrongPrice:
			'Ha ingresado un precio para %{productName} en formato incorrecto',
		form: {
			supp: {
				min: 'Mínimo',
				max: 'Máximo',
				required: 'Obligatorio',
				minMax: 'Min/Max',
				name: 'Nombre',
			},
			name: 'Nombre',
			shortName: 'Abreviatura',
			description: 'Descripción (Opcional)',
			price: 'Precio',
			price_default: 'Precio predeterminado',
			tax: 'IVA',
			type: 'Tipo',
			category: 'Categoría/s',
			no_place: 'No',
			place: 'Punto de elaboració 1',
			place2: 'Punto de elaboració 2 (Opcional)',
			statusSend: 'Tiempo de envío',
			visible: 'Visible',
			exclusion: 'Exclusiones',
			direct: 'Directo',
			sku: 'SKU (Opcional)',
			sku_required: 'SKU (Obligatorio)',
			free_price: 'Precio libre',
			no_name: 'Sin título',
			retail_price: 'Precio al detalle',
			select: 'Seleccionar...',
		},
		speedDial: {
			add_product: 'Nuevo producto (forma detallada)',
			add_product_line: 'Nuevo producto (rápido)',
			add_product_menu: 'Añadir productos de un menú existente',
		},
		assign_form: {
			search: 'Buscar',
		},
		filter: {
			add_filter: 'Añadir filtro',
			color: 'Color',
			price: 'Precio',
			taxe_rate: 'IVA',
			product_type: 'Tipo',
			category: 'Categoría',
			place_send: 'Punto de elaboració',
			visible: 'Visible',
			no_category: 'Sin categoría',
			search: 'Buscar...',
		},
		sort: {
			category_asc: 'Orden ascendente de categorías',
			category_desc: 'Orden descendente de categorías',
			category_A_Z: 'Categoría  A-Z',
			category_Z_A: 'Categoría  Z-A',
			product_asc: 'Nombre del producto A-Z',
			product_desc: 'Nombre del producto Z-A',
			price_asc: 'Precio ascendente',
			price_desc: 'Precio descendente',
			visible: 'Visible',
			not_visible: 'No visible',
			or: ' O ',
		},
		types: {
			solid: 'Sólido',
			alcool: 'Alcohol',
			no_alcool: 'Sin Alcohol',
		},
		popUpAddProduct: {
			title: 'Importar productos del menú: ',
			import: 'Importar',
		},
		grid: {
			price_mode: 'Gestión de precios',
		},
	},
	url: {
		category: 'categorie',
		product: 'produit',
		tax: 'tax',
		supplement: 'supplement',
		placeSend: 'placeSend',
		product_menu: 'produitMenu',
		price_category: 'categoriePrix',
		floor: 'salle',
		category_display: 'Categorías',
		product_display: 'Productos',
		tax_display: 'IVA',
		supplement_display: 'Complementos',
		placeSend_display: 'Fabricacíon',
		menu_display: 'Menús',
		price_category_display: 'Categorías de precios',
		floor_display: 'Salas',
		deploy: 'gestionDeploiement',
		deploy_display: 'Despliegue',
		menu: 'menu',
		menuBin: 'corbeilleMenu',
	},
	header: {
		update: 'Edite el nombre de los cambios',
		delete: 'Eliminar los cambios actuales',
		menu: 'Administración del menú',
		logout: 'Salir',
		reset: 'Eliminar cambios de %{date}',
		espaceClient: 'La meva àrea de clients',
	},
	deploy: {
		title: 'Administración de la implementación',
		titleNewClient: 'Inicialización de la carta',
		recap: {
			name: 'Nombre del cambio',
			dateDeploy: 'Fecha de implementación',
			nameRestaurant: 'Los cambios se han implementado en su institucion',
			children: 'También se realizaron cambios a las siguientes instituciones',
			title: 'Resumen',
			description:
				'Tu cambio del %{date} del menu "%{nameMenu}" será implementado.',
			immediatly: 'Inmediatamente',
			immediateDeploy:
				'Los datos se transmiten al iPad y se tendrán en cuenta en la fecha prevista.',
			deployedLater:
				'Has elegido realizar una modificación en una fecha posterior a la actual. Puedes efectuar cambios hasta la víspera de la fecha elegida. Para aplicarlos, tendrás que volver a realizar la modificación.',
			when: '¿Cuándo?',
			company: 'Establecimiento',
			other_companies: 'Establecimiento/s adicional/es',
			and_other_companies: 'Et %{nb} adicional/es establecimiento/s',
			zone: 'Zona',
		},
		warning: 'Atención',
		weirdVatProducts:
			'Los siguientes productos tienen un IVA incompatible con el tipo de producto. Si este no es el caso en su contexto, ignore esta información.',
		weirdPlaceSendProducts:
			'Los siguientes productos tienen un punto de fabricación incompatible con el tipo de producto. Si este no es el caso en su contexto, ignore esta información.',
		confirm_weird:
			'Algunos productos tienen un IVA o punto de fabricación incompatible con el tipo de producto. ¿Todavía desea aplicar estos cambios?',
		unknowVAT: 'IVA desconocido',
		unknowPlaceSend: 'Punto de fabricación desconocido',
		dayOfDeploy: 'El día del despliegue',
		form: {
			name: 'Nombre del cambio',
			date: 'Fecha de implementación',
			now: 'Implementar ahora',
			later: 'Implementar más tarde en la fecha',
			createMenu: 'Inicialización de la carta',
			submit: 'Validar',
			nameNewClient: 'Nombre de la carta',
			placeHolderRestaurant: 'Nombre del establecimiento',
			restaurant_select_all: 'Seleccionar / deseleccionar',
			restaurant_empty: 'Sin restaurante',
			toBeDeployed: 'Despliegue previsto en : ',
			noDeploy: 'No se puede cambiar la selección',
			assignRestaurant: 'Implementar también a otros establecimientos',
			impossibleAction:
				'Desplegar - Acción imposible, un despliegue ya está planeado.',
			titleAssignator: 'Elige los establecimientos',
			warningDeleteChildren:
				'Atención, esto eliminará la implementación en los establecimientos previamente elegidos',
			warningDate:
				'No se puede implementar un cambio si la fecha y la hora son inferiores a la última implementación programada',
			defaultName: 'Modificación del %{date}',
		},
	},
	tax: {
		name: 'Nombre',
		rates: 'Tipo',
		rate: 'Tipo para tomar en el restaurante (%)',
		rate_take_away: 'Tipo para llevar (%)',
		labelTaxRateError: 'Debes introducir un número entre 0 y 100.',
		confirm: '¿Seguro que quieres eliminar este IVA?',
		default: 'Predeterminado',
		active: 'Activo',
		editConfirm:
			'Atención: este IVA está asociado a à %{count} producto/s, ¿seguro que quieres modificarlo?',
	},
	category: {
		empty: 'No has configurado ninguna categoría de productos',
		empty_exemple: 'Ejemplos : Entrentes, Tapas, Bebidas...',
		name: 'Nombre',
		color: 'Color',
		icon: 'Icono',
		all: 'Todos los productos',
		confirm:
			"¿Seguro que quieres eliminar esta categoría? No se eliminarán los productos de esta categoría, y seguirán visibles en 'Todos los productos'.",
		deleteConfirm: 'Confirmación de eliminación',
		no_category: 'Sin categoría',
		already_selected: 'Ya seleccionado',
		product: {
			price: 'Precio a la carta',
			type: 'Tipo',
			tax: 'IVA',
			place_send: 'Punto de elaboració',
			places_send: 'Puntos de elaboració',
			alert_price:
				'No puedes añadir productos con un valor igual a 0.00%{currency} en un menú.',
		},
	},
	supplementCategory: {
		empty: 'No has configurado ningun complemento',
		empty_exemple: ' Ejemplos : Cocción, Salsa, Heladios...',
		form_name: 'Nombre del complemento',
		name: 'nombre',
		color: 'color',
		icon: 'icono',
		type1: 'Una opción',
		type2: 'Varias opciónes',
		type3: 'Varias opciónes en stock',
		confirm: '¿ Seguro que desseas eliminar esta categoría?',
		assign_product: 'Assignar productos',
		min: 'min',
		max: 'max',
		labelMultiple:
			'Los artículos relacionados se rigen por la siguinte regla (0 = opcional)',
		labelUnique: 'La elección para los artículos relacionados es',
		add: 'Añadir categoría de complemento',
		changes_confirmation:
			'¿Quieres aplicar los cambios en los productos relacionados con esta categoría de complemento?',
	},
	floorPriceCategoryReweight: {
		title: 'Gestión de las prioridades de las categorías de precios',
		name: 'Categoría de precios',
		floors: 'Salas',
	},
	supplement: {
		empty: 'No has configurado ningun subcomplemento',
		empty_exemple: 'Ejemplos : Cruda, Sellada, Brava, De oporto ...',
		name: 'Nombre del subcomplemento ',
		price: 'Precio',
		confirm: '¿Seguro que quieres eliminar este complemento?',
		add: 'Añadir subcomplemento',
		addSpace: 'Nuevo espacio',
		sku: 'SKU',
	},
	generalButtons: {
		cancel: 'Cancelar',
		ok: 'Ok',
		yes: 'Sí',
		no: 'No',
		edit: 'Modifier',
		delete: 'Eliminar',
		reload: 'Recargue',
	},
	user: {
		login: 'Conectarse',
		confirmLogout: '¿Seguro que quieres cerrar sesión?',
	},
	productMenu: {
		name: 'Nombre',
		shortName: 'Abreviatura',
		empty: 'No has configurado ningun menú',
		price: 'precio',
		tax: 'IVQ',
		visible: 'Visible',
		confirmMenu: '¿Seguro que quieres eliminar este menú?',
		confirmMenuLevel: '¿Seguro que quieres eliminar este sección del menú?',
		nbMenuLevel: 'SECCIONES',
		nbProduct: 'Productos',
		next: 'Próximo',
		back: 'Regreso',
		levelName: 'Sección del menú',
		excludedOn: 'El menú es excluido para :',
		title: 'Lista de menús',
		noProduct:
			'Necesitas añadir productos con un precio mayor que 0 para crear un menú',
		form: {
			titleCreation: 'Creando un menú',
			titleCreation2: 'Creando un menú : ',
			name: 'Nombre del menú',
			short_name: 'Abreviatura (Optional)',
			sku: 'SKU (Optional)',
			sku_required: 'SKU (Obligatorio)',
			color: 'Color del Menú',
			description: 'Descripción (Optional)',
			errorName: 'Campo obligatorio', ////// HERE
			errorPrice: 'El número debe ser positivo',
			price: 'Precio',
			defaultPrice: 'Precio predeterminado',
			step1: 'Denominación',
			step2: 'Precios y exclusiones',
			step3: 'Gestión del producto',
			step4: 'Validación',
			submit: 'Validar',
			defaultMenuLevelName1: 'Entrantes',
			defaultMenuLevelName2: 'Platos',
			is_alternative: 'Opcional',
			is_required_manufacturing: 'Obligatorio para el envío a cocina',
			add_menu_level: '+ Añadir una sección al menú',
			menu_level_form_name: 'Formulario de sección del menú',
			product_menu_level_form_name: 'Formulario de producto',
			active: 'Activo',
		},
	},
	menu: {
		addNew: 'Añadir una nueva carta',
		active: 'Activo',
		goToBin: 'Ver cartas eliminadas',
		modify: 'Modificar la carta',
		deploy: 'Administrar la implementación',
		return: 'Vuelta hacia atrás',
		inactive: 'Inactivo',
		removed: 'Eliminada',
		update: 'Modificar',
		permanent: 'Permanente',
		startDate: 'Fecha de inicio',
		endDate: 'Fecha del fin',
		resume: 'Resumen',
		company: 'Establecimiento',
		institution: 'Establecimiento(s)',
		product: 'Producto(s)',
		carteSelected: 'Carta activa en el iPad',
		noAccess: 'Para acceder a la carta, por favor sincronice desde su iPad',
		category: 'Categoría(s)',
		menu: 'Carta(s)',
		createMenu: 'Te invitamos a crear tu primera carta',
		titlePopUpEdit: 'Modificar las informaciones',
		nextPatch: 'Siguiente despliegue',
		dateDeploy: 'Fecha de despliegue',
		noFuturPatch: 'No despliegue planeado',
		synchroCells: {
			never: 'Nunca desplegado',
			noSynchro: 'No sincronizado',
			synchroNoDeployed: 'Sincronizado no aplicado',
			synchro: 'Sincronizado',
		},
		synchroInstitution: 'Sincronización de los establecimientos',
		synchro: 'Sincronización',
		access: 'Acceso',
		deleteMenu:
			'¿Estás seguro de que quieres eliminar esta carta? Atención, esta acción es irreversible.',
		cards: 'carta(s)',
		currentCard: 'Carta en curso',
		pastCard: 'Carta vencida',
		futurCard: 'Siguiente carta',
		neverCurrentCard: 'No carta en curso',
		neverPastCard: 'No carta vencida',
		neverFuturCard: 'No siguiente carta',
		form: {
			name: 'Nombre de la carta',
			placeholderName: 'Invierno',
			errorName: 'Campos obligatorios',
			startDate: 'Fecha del inicio',
			warning:
				'Advertencia, quieres borrar esta carta, ¿estás seguro? Esta carta será visible y recuperable por 1 mes antes de su eliminación final.',
			warning2:
				'Atención, quieres restaurar esta carta, ¿estás seguro? Esta restauración puede llevar a duplicados con sus productos.',
			warningCopy:
				'Crear una nueva carta útil solo si desea tener al menos el 50% de sus elementos diferentes de su carta actual',
			endDate: 'Fecha del fin',
			new: 'Nueva carta',
			copy: 'Copia de',
			deAssign: 'Desasignar',
			submit: 'Validar',
			submit2: 'confirmar',
			add: 'Nueva carta',
			warningCopyMenu:
				"Una vez que se copien los elementos de la carta, la nueva carta se implementará automáticamente sin que tenga que implementarla a través de la sección 'Implementar'. Recuerde especificar una fecha de inicio si no desea que esté disponible en el iPad tan pronto como la próxima sincronización o apertura del servicio.",
			cancel: 'Anular',
			save: 'Registro',
		},
		formCopy: {
			choiceElement: 'Elección de elementos a copiar',
			category: 'Categorías de productos',
			categorySupplement: 'Categorías de suplementos',
			productsSelected: 'Producto(s) seleccionado(s)',
			menusSelected: 'Carta(s) seleccionada(s)',
			supplementSelected: 'Suplemento(s) seleccionado(s)',
			menus: 'Menús(s)',
			integralCopy: 'Copia la carta completo',
		},
		patch: 'Patch en curso',
		patchName: 'Nombre del patch',
		nbModif: 'Modificacione(s)',
		carteAccess: 'Acceso a la carta',
		startDatePermanent: 'Fecha del inicio permanente',
		endDatePermanent: 'Fecha del fin permanente',
		addMenuToRestaurant: {
			title: 'Enlacer los establecimientos a la carta',
			select: 'Selección de los establecimientos',
			selected: 'Establecimientos conectados',
			name: 'Nombre',
			beginDate: 'Fecha del inicio',
			endDate: 'Fecha del fin',
			edit: 'Modificar',
			validate: 'Validar',
			cancel: 'Cancelar',
			add: 'Ańadir',
			delete: 'Eliminar',
			close: 'Cercar',
			selectRestaurant: 'No establecimiento para asignar',
			untiedRestaurant: 'No establecimiento conectado a la carta',
			confirmAssign:
				'¿Estás seguro de que quieres asignar este establecimiento?',
			confirmUntied:
				'¿Estás seguro de que quieres eliminar la asignación de este establecimiento?',
			assign: 'asignar',
		},
		acess_menu_allready_in_use:
			'Cuidado, parece que una sesión ya está abierta con esta tarjeta. ¿Todavía quieres acceder a ella?',
		edits: {
			title: 'Los cambios que vendrán',
			no_edits: 'No hay ninguna modificación en curso para esta tarjeta',
			create: 'creación de %{type}',
			update: 'cambio de %{type}',
			delete: 'eliminación de %{type}',
			minorEdits: '%{nbModifs} modificaciones misceláneas',
			remainingEdits: 'et %{nbModifs} otros cambios',
			type: {
				category: 'de categoría.s',
				product: 'de producto.s/menu.s/suplemento.s',
				price: 'de precio.s',
				category_supplement: 'de Opción.es de cocina',
				price_category: 'de categoría.s de precios',
				category_product: 'de enlaces categoria-producto',
				product_product: 'de enlaces producto-menu o producto-sopcionde cocina',
			},
		},
	},
	priceCategory: {
		name: 'Nombre',
		name_short: 'Abreviatura',
		time: 'Duración',
		days: 'Días',
		floors: 'Salas',
		associations: 'Mostrar asociaciones',
		active: 'Activo',
		deleteConfirm:
			'¿Está seguro de que quiere eliminar esta categoría de precios? Los precios asociados se suprimirán todos los plazos incluidos.',
		empty_exemple: 'Ejemplos : Happy Hour, Terraza...',
		form: {
			name: 'Nombre',
			name_short: 'Abreviatura (Opcional)',
			time: 'Duración',
			days: 'Días',
			days_label: 'Categoría de precio válida:',
			floors: 'Salas',
			floors_label: 'Categoría de precio válida en:',
			active: 'Activo',
			start: 'Inicio',
			end: 'Fin',
			all_day: 'Todo el día',
			display_settings: 'visualización de la abreviatura',
			display_short_name_order: 'Ver la abreviatura en la comanda',
			display_short_name_cashing: 'Ver la abreviatura en el cobro',
			display_short_name_manufacturing: 'Ver la abreviatura en elaboración',
			associations: 'Mostrar asociaciones',
			times: {
				from: 'De',
				to: 'a',
				tomorrow: 'del día siguiente',
			},
			init: {
				button: 'Configuración rápida',
			},
			copy: {
				button: 'Copiar desde otra categoría',
			},
			copyConfirmation:
				'Vas a copiar en esta categoría los precios de la categoría "%{categoryName}". Si los precios se han facilitado previamente, se sobrescribirán. ¿Deseas continuar?',
			initConfirmation:
				'Vas a configurar esta categoría de precio. Si los precios han sido facilitados anteriormente, se sobrescribirán. ¿Deseas continuar? ',
		},
		init: {
			no_category: 'Por favor, configure categorías y asocie productos',
			no_other_price_category: 'No has configurado otra categoría de precios',
			none: 'Ningún cambio',
			reinit: 'Restablecer (Eliminar precios)',
			price_fixed: 'Precio fijo',
			price_plus: 'Importe de más',
			price_minus: 'Importe de menos',
			percent_plus: '% de más',
			percent_minus: '% de menos',
		},
		popupAssociation: {
			type: 'Tipo',
			name: 'Nombre',
			price: 'Precios',
			product: 'Producto',
			productMenu: 'Menú',
			supplement: 'Complemento',
			productInMenu: 'Producto de menú',
			empty:
				'No hay productos / menú / complemento asociados con esta categoría de precio',
		},
		button_floor_price_category_weight:
			'Administrar prioridades de categorías de precios',
		initPercentage: {
			initsentence: 'Por favor, elija el método para redondear en %',
			warning: 'Atención',
			aroundMath: 'Redondeo matematico',
			aroundTen: 'Redondeo a 0,10',
			aroundFive: 'Redondeo a 0,05',
			cancel: 'Cancelar',
		},
		alert:
			'Les Catégories de prix sont GLOBALES. Si vous supprimez une catégorie de prix vous supprimez également tout les prix associés a cette catégorie de prix pour toutes les dates.',
	},
	patchMenu: {
		deniedAccessText:
			'No puedes acceder a tu cuenta porque se están llevando a cabo modificaciones.',
		confirmDelete: '¿Estás seguro de que quieres eliminar este patch?',
		deniedAccessTitle: 'Attention',
		deniedAccessQuit: 'Quitter',
		ipadChanges: 'Los datos en el iPad se han sincronizado',
		ipadChangesCompany:
			'Los datos en el iPad se han sincronizado con el establecimiento %{nameCompany} - Carta : %{nameMenu}',
		ipadNoChanges:
			'Los datos del ipad no pudieron ser sincronizado con la carta: %{nameMenu}',
		ipadNoChangesCompany:
			'Los datos del ipad no pudieron ser sincronizado con el establecimiento %{nameCompany} - Carta : %{nameMenu}',
		deniedModification:
			'No puedes realizar cambios porque se están llevando a cabo modificaciones.',
		warningDelete:
			'¿Estás seguro de que quieres borrar los cambios actuales del %{date}? Los valores de la fecha seleccionada volverán a sus valores originales antes de la modificación.',
		deploySuccess: 'Modificación realizada con éxito.',
		deploySuccessSynchro:
			'Modificación realizada con éxito. Por favor, sincroniza tu iPad.',
		resetSuccess: 'Se han eliminado las modificaciones del dia.',
		noModifications:
			'No se puede llevar a cabo ninguna modificación porque no se han producido cambios en la carta.',
		disabledMasterMultiSelected:
			'Seleccione una zona para implementar los cambios en el menú',
		updateFail: 'Error al actualizar los cambios. Cambio no realizado',
		deployFail: 'Error, Combio no realizado',
		updatePatch: {
			title: 'Modificar',
			where: 'Estás en la pantalla de edición de patches.',
			secondTitle: 'Modificar el nombre del patch',
			thirdTitle: 'Cambiar la hora de despliegue',
			failPrev: 'No puedes editar una fecha antes de un patch existente',
			failAft: 'No puedes cambiar una fecha después de un patch existente',
			failTime: 'No puedes ingresar una fecha antes de la hora actual',
			failBefore24H: 'No se puede implementar un patch antes de un día',
		},
		deletePatchFail:
			'No puede eliminar una implementación antes de otra implementación programada',
		errorDeletePatch: 'Vaya, se ha producido un error',
		display: {
			title: 'Siguientes patches',
			date: 'Fecha de despliegue',
		},
		deleteSuccess: 'Despliegue exitosamente eliminado',
	},
	floor: {
		no_configuration_here: 'Configura las salas desde tu iPad',
		empty: 'No has configurado ningun sala',
		no_floors: 'No Sala',
		all: 'Todas las salas',
		name: 'Nombre',
		type: 'Tipo',
		takeaway: 'Comida rápida',
		counter_type: {
			stay_in: 'En el establecimiento',
			take_away: 'Para llevar',
			delivery: 'A domicilio',
		},
		form: {
			name: 'Nombre',
			color: 'Color',
			category: 'categoría',
			counter: 'Barra',
			general_options: 'Opciones generales',
			advanced_options: 'Opciones avanzadas',
			auto_order_closing: 'Cierre qutomático',
			payment_after_ticket: 'Cobro después del ticket',
			flash_cashing: 'Cobro rápido',
			client: 'Ouverture automatique des clients',
			counter_type: 'Tipo',
			display_amount: 'Mostrar el importe de las comandas',
			print_auto_manufacturing_close: 'nvío automático al cerrar una comanda',
			print_auto_manufacturing_exit: 'Envío automático al salir de una comanda',
			print_auto_ticket_close_nb:
				'Número de tickets impresos automáticamente cuando se cierra una comanda',
			print_auto_ticket_close_cashed: 'Mostrar el importe cobrado en el ticket',
			print_auto_ticket_close_status: 'Incluso si el ticket ya ha sido impreso',
		},
		exclusion_label: 'Excluido para :',
		alert_floor_mapping:
			'Una o más salas de la cuenta no están vinculadas al menos a una sala de cada sitio, comuníquese con el servicio técnico',
	},
	days: {
		monday: 'Lunes',
		tuesday: 'Martes',
		wednesday: 'Miércoles',
		thursday: 'Jueves',
		friday: 'Viernes',
		saturday: 'Sábado',
		sunday: 'Domingo',
		EVERY_DAY: 'Todas las dias',
		no_days: 'Ningun dia',
	},
	retail: {
		type: {
			size: {
				title: 'Precio por metro',
				unit: 'Metro',
				subUnit: 'centímetro',
			},
			time: {
				title: 'Precia por hora',
				unit: 'Hora',
				subUnit: 'minuto',
			},
			volume: {
				title: 'Precio por litro',
				unit: 'Litro',
				subUnit: 'centilitro',
			},
			weight: {
				title: 'Precio al peso',
				unit: 'Kg',
				subUnit: 'gramo',
			},
		},
	},
	assignator: {
		tag_empty: 'No hay productos seleccionados',
		category: 'categoría',
		menuLevel: 'Sección del menú',
		supplementCategory: 'Categoría de complemento',
	},
	zone: {
		company_loaded: 'La carte de %{name} han sido cargado can éxito',
		zone_loaded: 'La carte de %{name} han sido cargado can éxito',
		master_loaded: 'La carta del sede principal ha cargado con éxito',
	},
	helper: {
		title: 'Rúbrica ayuda',
		button: 'Necesitas ayuda ?',
		close: 'Cerca',
		titles: {
			product: 'Productos',
			category: 'Categorías',
			supplement: 'Opciones Cocina',
			menu: 'Menús',
			priceCategory: 'Categorías de precio',
			tax: 'I.V.A.',
			quickstart: '¿Por dónde empezar?',
			video: 'Demostración',
			master: 'Master Multi-établissements',
		},
		sections: {
			quickstart: {
				title: '¿Por dónde empiezo?',
				paragraph_1:
					'Bienvenido/a a la herramienta de creación de cartas online. Aquí podrás crear y personalizar la carta de tu establecimiento',
				paragraph_2:
					'La "Carta Web" te permite crear todos los elementos de tu carta: categorías, productos, complementos, menús...etc. ',
				paragraph_3:
					'Consulta el apartado “Ayuda” para una utilización óptima de nuestra herramienta.',
				paragraph_4:
					'Para ir a este apartado, haz clic en el botón "¿Necesitas ayuda?", que aparece en la parte superior derecha de la pantalla.',
			},
			product: {
				title: 'Productos',
				create: {
					title: 'Crear un nuevo producto',
					paragraph_1:
						'Para añadir un nuevo producto, haz clic en “+”. Aparecerán dos iconos a ambos lados de “+”:',
					fast: {
						title: '"Nuevo producto (rápido)" ',
						warning: 'Atención',
						paragraph_1:
							'(a la izquierda): permite añadir una línea suplementaria en la pantalla de comandas para poder crear un producto rápidamente. En esta nueva línea, podrás introducir las informaciones del producto haciendo clic directamente en cada campo',
						paragraph_1_2:
							"su nuevo producto sólo se guardará después de que haga clic en el icono verde'+' al final de la línea.",
						paragraph_2:
							'Si quieres acceder al formulario detallado del producto, haz clic en el icono de modificación (lápiz) que aparece al final de la línea.',
						paragraph_3:
							'Si varios de tus productos tienen el mismo precio, I.V.A., Tipo, Categoría y/o Punto de elaboración, hay una opción que te permite simplificar esta tarea, evitándote tener que completar cada campo uno a uno.',
						paragraph_4:
							'Para ello, crea tus productos en “Nuevo producto (rápido)”. Introduce el nombre de cada uno y el precio del primer producto. Después, haz clic en el campo “Precio” (de dicho producto). El campo aparecerá en azul con un pequeño cuadrado en el ángulo inferior izquierdo. Con la ayuda de tu ratón, desliza dicho cuadrado hasta las líneas de los otros productos: el precio se aplicará a todos los productos seleccionados',
						paragraph_5:
							'Puedes realizar la misma acción con el I.V.A., el tipo, la categoría y el punto de elaboración.',
					},
					form: {
						title: '"Nuevo producto (formulario detallado)" ',
						content:
							'(a la derecha): permite acceder al formulario detallado de creación del producto.',
						list: {
							name: {
								title: 'Nombre : ',
								content:
									'(campo obligatorio) nombre del producto utilizado por defecto en la aplicación.',
							},
							short_name: {
								title: 'Abreviatura : ',
								content:
									'abreviatura del producto utilizada, si la opción está activada, en la pantalla de comandas y/o en las impresiones (en cocina y el ticket de caja).',
							},
							sku: {
								title: 'SKU : ',
								content:
									'si cuentas con varios establecimientos con platos en común, pero con nombres diferentes, introduce un código común para ambos.',
							},
							description: {
								title: 'Descripción : ',
								content:
									'descripción o comentario del plato. Accesible desde la pantalla de comandas.',
							},
							price: {
								title: 'Precio : ',
								content: 'Precio final del producto.',
								paragraph_1:
									'El precio puede ser “Libre”, es decir, que la aplicación solicitará el precio deseado al seleccionarlo.',
								paragraph_2:
									'El precio también puede ser “al detalle”. Para ello, marca la opción “precio al detalle”, selecciona la unidad de medida (precio al kilo, metro, litro u hora) e introduce el precio.',
								paragraph_3:
									'Si has añadido categorías de precio (ej.: Happy hour), en la rúbrica “Categoría de precio”, aquí podrás decidir si quieres atribuir o no la categoría de precio a este producto y definir el precio para el producto en cuestión.',
							},
							visible: {
								title: 'Visible : ',
								content:
									'muestra el producto en la pantalla de comandas (si el producto no está disponible temporalmente o fuera de temporada) sin tener que eliminarlo definitivamente de la carta.',
							},
							vat: {
								title: 'IVA : ',
								content:
									'(campo obligatorio) selecciona la tasa de I.V.A. a aplicar en este producto.',
								paragraph:
									'Existen tres tasas pre-configuradas. Puedes configurar nuevas tasas en el apartado « IVA ».',
							},
							type: {
								title: 'Tipo : ',
								content:
									'(campo obligatorio) El tipo de producto se utiliza para los informes de tus establecimientos (disponibles en el Portal de Informes online). La lista de tipos de productos está pre-definida. Cuando hagas clic en el campo “Tipo”, verás la lista de todos los tipos, con la siguiente información: Sólido, Alcohol o Sin alcohol.',
							},
							category: {
								title: 'Categoría : ',
								content:
									'permite clasificar el producto en una o varias de las categorías que hayas creado en el apartado ”Categoría” (puedes seleccionar varias categorías).',
							},
							place_send_1: {
								title: 'Punto de elaboración 1: ',
								content:
									'lugar de envío del producto (lugar en el que se imprimirá la orden de elaboración). Por defecto, el punto de elaboración para nuevos productos es « Cocina ».',
							},
							place_send_2: {
								title: 'Punto de elaboración 2 : ',
								content:
									'lugar secundario de envío del producto (segundo lugar al que se enviará la orden de elaboración).',
							},
							status_send_index: {
								title: 'Tiempos de envío : ',
								content:
									'tiempo de envío del producto (Directo, Envío 1, Envío 2, Envío 3...). Por defecto, el tiempo de envío es “Directo” para los nuevos productos.',
							},
							supplements: {
								title: 'Complementos : ',
								content:
									'quí aparecen las opciones de cocina creadas en el apartado « Opciones cocina ».',
								paragraph_1:
									'Seleccionando una o varias categorías de complementos, aparecerá un menú desplegable a la derecha de la aplicación (en la pantalla de comandas) para indicarle al camarero que seleccione los complementos (por ejemplo ➔ plato: Entrecot, complementos: Cocción, Acompañantes y/o Salsa).',
								paragraph_2:
									'Estos complementos pueden ser obligatorios u opcionales, o tener un mín./máx. para las opciones que permitan seleccionar varias (ver el apartado “Opciones cocina” para más información)',
							},
							color: {
								title: 'Color : ',
								content:
									'color del botón del producto en la pantalla de comandas.',
							},
							exclusions: {
								title: 'Exclusiones : ',
								content:
									'esta funcionalidad permite elegir las salas en las que el producto no aparecerá.',
							},
						},
						paragraph_1_bold:
							'Para guardar el producto, haz clic en el botón azul « Añadir »',
						paragraph_1:
							'en la parte superior derecha del formulario. Si este botón aparece en gris, significa que alguno de los tres campos obligatorios (nombre, IVA o tipo) está vacío.',
						paragraph_2:
							'Si quieres que un producto esté disponible para un menú pero que no aparezca en la carta, crea una categoría “Menú” en la que añadirás todos los productos de tus menús y excluye esta categoría de todas las salas. Cuando hayas instalado la aplicación, solo tendrás que ir a « Configuración/Opciones » y marcar « Autorizar la selección de un producto no visible en el menú».',
					},
				},
				update: {
					title: 'Modificar un producto',
					paragraph_1:
						'En la lista de productos, puedes modificar los datos de un producto personalizado haciendo clic directamente en el campo del producto que desees modificar (en la pantalla de comandas). Ejemplo: modificar el color, nombre, categoría...etc.',
					paragraph_2:
						'Si quieres acceder al formulario detallado del producto, haz clic en el icono de modificación (lápiz) que aparece al final de la línea.',
				},
				cancel_update: {
					title: 'Anular una modificación',
					paragraph:
						'En la parte superior derecha de la lista de productos aparecen dos flechas. Estas permiten anular la última modificación efectuada (flecha hacia la izquierda) o volver a la última modificación anulada (flecha hacia la derecha).',
				},
				reweight: {
					title: 'Organizar los productos en la pantalla de comandas',
					paragraph:
						'Encontrarás las categorías de productos a la izquierda de la lista de productos. Haz clic en una de las categorías para ver los productos asociados a dicha categoría. De esta forma, podrás ver cómo aparecen los productos en la pantalla de comandas.',
					list: {
						item_1:
							'Para reorganizarlos, haz clic en uno de los productos y deslízalo hasta el lugar deseado.',
						item_2:
							'Para añadir espacios, haz clic en el botón “espacio” y deslízalo hasta el lugar deseado. Los “espacios” son espacios vacíos, por si quieres hacer una presentación en concreto.',
						item_3_1:
							'Para añadir un producto, haz clic en el botón “Añadir un producto” y deslízalo hasta el lugar deseado.',
						item_3_2:
							'En la ventana que aparece, selecciona el o los productos que desees añadir marcando la casilla que aparece a la derecha de la línea. El campo “Buscar” te permite encontrar fácilmente un producto por su nombre. También puedes buscar un producto en una de las categorías de la izquierda.',
						item_3_3:
							'Haz clic en el botón “Añadir” que aparece en la parte superior derecha del formulario. El o los productos añadidos aparecerán en la parte inferior de la pantalla (en la pantalla de comandas). Puedes desplazarlo hasta el lugar que desees.',
						item_4:
							'Para eliminar un producto de una categoría, selecciona el producto que quieras eliminar y haz clic en el icono rojo que aparece en la parte superior derecha. El producto seguirá apareciendo en la lista general pero ya no pertenecerá a ninguna categoría.',
						item_5:
							'Para modificar un producto, haz clic en dicho producto y se abrirá un formulario en el que podrás efectuar dichas modificaciones',
					},
				},
				filters: {
					title: 'Filtros',
					content:
						'Puedes filtrar la lista que has creado. Existen varias opciones :',
					list: {
						search: {
							title: 'Buscar : ',
							content: 'realizar una búsqueda por nombre de los productos.',
						},
						sort: {
							title: 'Ordenar : ',
							content:
								'ordenar por categoría, por orden alfabético de los productos o por precio.',
						},
						add_filter: {
							title: 'Añadir un filtro : ',
							content:
								'mostrar solo los productos que correspondan con un determinado color, precio, I.V.A., tipo, categoría o punto de elaboración.',
						},
					},
				},
			},
			category: {
				title: 'Categorías',
				paragraph_1:
					'Las categorías permiten organizar la carta agrupando los platos y/o bebidas según el orden deseado. Esta sección permite gestionar las categorías en las que tus productos serán clasificados.',
				paragraph_2: 'Para crear una nueva categoría, haz clic en « + ».',
				paragraph_3:
					'En el formulario que aparece a la derecha, introduce el nombre de la categoría, el color, el icono y, eventualmente, la o las salas en las cuales no quieres que aparezca dicha categoría, y haz clic en “Añadir” en la parte superior derecha del formulario.',
				paragraph_4:
					'Puedes reorganizar el orden de las categorías creadas. Para ello, selecciona una de las categorías haciendo clic en el icono de tres líneas (a la izquierda del nombre de la categoría) y trasládala deslizándola hasta el lugar deseado de la lista.',
				paragraph_5:
					'Para modificar una categoría, haz clic en la que desees modificar y cambia los datos en el formulario que aparece a la derecha.',
				paragraph_6:
					'Para eliminar una categoría, haz clic en el icono “eliminar” al final de la línea. Si hay productos asociados a la categoría eliminada, los productos se guardarán pero ya no pertenecerán a ninguna categoría.',
				paragraph_7:
					'Si quieres que un producto esté disponible para un menú pero que no aparezca en la carta, crea una categoría “Menú” en la que añadirás todos los productos de tus menús y excluye esta categoría de todas las salas. Cuando hayas instalado la aplicación, solo tendrás que ir a “Configuración/Opciones” y marcar “Autorizar la selección de un producto no visible en el menú”.',
			},
			supplement: {
				title: 'Opciones Cocina',
				paragraph_1:
					'Este apartado recoge todas las opciones que se pueden añadir (gratis o no, obligatorias o no) a un producto. Estos complementos aparecen como categorías de opciones de cocina (ej.: Cocción) y pueden ser asociadas a diferentes opciones (ej.: cruda, muy hecha...etc.).',
				create: {
					title: 'Crear una categoría de opciones de cocina',
					paragraph_1:
						'Para crear un complemento, haz clic en el botón "Nueva categoría".',
					list: {
						name: {
							title: 'Nombre de la categoría : ',
							content: 'nombre de la categoría. Apuesta por un nombre corto',
						},
						required: {
							title: 'Obligatoria : ',
							content:
								'marca esta casilla si quieres que la opción sea obligatoria cuando se elija un producto. Si un complemento ha sido configurado como obligatorio, el camarero no podrá enviar la comanda al punto de elaboración hasta que haya seleccionado todos los complementos',
							paragraph:
								'También puedes dejar esta opción desactivada y activarla más tarde, de manera individual, en el formulario de los productos que quieras seleccionar. Así, esta opción podrá ser obligatoria para algunos productos y opcional para otros.',
						},
						type: {
							title: 'Tipo : ',
							content: 'existen tres tipos de complementos:',
							list: {
								unique: {
									title: 'Una opción ',
									content:
										'permite seleccionar un único complemento: por ejemplo, la cocción de la carne.',
								},
								multiple: {
									title: 'Varias opciones ',
									content:
										'permite seleccionar varios complementos pero en una sola cantidad: por ejemplo, el cliente quiere una ensalada y patatas fritas como acompañante.',
								},
								multiple_quantity: {
									title: 'Varias opciones y cantidades ',
									content:
										'permite seleccionar varios complementos en X cantidades: por ejemplo, si el cliente desea 2 bolas de helado de vainilla y 1 bola de helado de chocolate, puedes hacer clic dos veces en “vainilla” y uno en “chocolate”.',
								},
							},
							description: {
								paragraph_1:
									'En función del tipo de complemento, el carácter obligatorio (o no) aparecerá de una manera distinta:',
								list: {
									unique: {
										title: '« Una opción » : ',
										content:
											'si la opción « Obligatoria » está activada, el camarero tendrá que añadir un complemento en la comanda. Si no, el complemento es opcional.',
									},
									multiple: {
										title:
											'« Varias opciones » o « Varias opciones y cantidades » : ',
										content:
											'para ambas opciones, es posible configurar un número mínimo y máximo de complementos. ',
									},
								},
								paragraph_2:
									'Por ejemplo, para un helado que lleva 3 bolas obligatoriamente, habrá que indicar mín.: 3/máx. 3. Si el número es "0", esto es facultativo. El camarero podrá seleccionar tantas bolas de helado como quiera (o incluso ninguna).',
								paragraph_3:
									'Puedes dejar la opción que viene por defecto (mín. 0 y máx. 0) y cambiarla más tarde, de manera individual, en el formulario de los productos que quieras seleccionar. Así, podrás crear mín./máx. diferentes para cada producto.',
							},
						},
						icon_color: {
							title: 'Icono y color : ',
							content:
								'elige el icono y el color que aparecerá en la categoría de opciones de cocina durante la comanda.',
						},
						exclusions: {
							title: 'Exclusiones : ',
							content:
								'esta funcionalidad permite elegir las salas en las que dicha categoría no aparecerá. ',
						},
					},
					paragraph_2:
						'Para modificar una categoría creada, haz clic en el lápiz',
				},
				reweight: {
					title: 'Orden de las opciones de cocina',
					paragraph:
						'Puedes reorganizar el orden de las categorías creadas. Para ello, selecciona una de las categorías (haciendo clic en el icono que aparece a la izquierda del nombre) y deslízala hacia el lugar deseado.',
				},
				supplements: {
					title: 'Definir las opciones de cocina de una categoría de opciones',
					paragraph_1:
						'Puedes añadir tantas opciones como quieras en una categoría de opciones.',
					paragraph_2:
						'Para ello, selecciona la categoría (haciendo clic en el nombre) y haz clic en “Nueva categoría”. En el formulario que aparece, añade el nombre, el color y las salas a excluir y haz clic en “añadir”.',
					paragraph_3:
						'Si se trata de una opción que conlleva un coste extra, introduce dicho importe (que se añadirá al importe inicial del producto).',
					paragraph_4:
						'Las opciones de cocina aparecen en la lista de manera similar a la que encontrarás en la pantalla de comandas. Puedes cambiar el orden haciendo clic sobre el producto y trasladándolo hacia el lugar deseado.',
					paragraph_5:
						'Para añadir espacios, haz clic en el botón “espacio” y deslízalo hasta el lugar deseado. Los “espacios” son espacios vacíos, por si quieres hacer una presentación en concreto. Puedes añadir tantos como quieras.',
				},
				associate: {
					title: 'Atribuir un complemento a un producto',
					paragraph:
						'Existen dos maneras de atribuir un complemento a un producto : ',
					list: {
						item_1:
							'1 / Hacer clic en el botón “Asignar productos” (libreta) y seleccionar los productos que quieras atribuir a esta opción.',
						item_2:
							'2 / También puedes hacerlo directamente en “Complemento” al seleccionar el producto deseado (en el apartado “Productos”).',
					},
				},
			},
			patchMenu: {
				title: 'Patches / Despliegues',
				create: {
					title: 'Qué es un patch ?',
					paragraph_1:
						'Cuando se accede a una carta desde la carta web, automáticamente se generará un archivo. Este archivo contiene el conjunto de modificaciones de la carta, que se ejecutará a partir de una fecha indicada en la aplicación.',
					paragraph_2:
						'Dicho archivo igualmente se generará aunque no haya modificaciones en la carta. Por defecto el nombre del archivo es "Modificaciones", seguido de la fecha de su creación. El nombre se puede cambiar pinchando sobre el icono del lápiz situado al lado del nombre del archivo (en la parte superior de la pantalla)',
					paragraph_3:
						'Desde la carta web se pueden modificar todos los aspectos de la carta. Estas modificaciones no surtirán efecto (por lo tanto no serán visibles en la aplicación)  hasta que no se haya programado y ejecutado el archivo de modificaciones.',
				},
				deploy: {
					title: 'Ejecución',
					paragraph_1:
						'Cuando se han terminado las modificaciones de nuestra carta, tenemos que ejecutarlas para que estén disponibles en la aplicación. La ejecución se puede llevar a cabo de dos formas: ',
					paragraph_2:
						'Pinchando en la rúbrica « ejecución » de la barra del navegador a la izquierda, cuando estamos en una carta.',
					paragraph_3:
						'Pinchando en el icono de calendario en la pantalla de gestión de cartas (accesible desde el menú de arriba en la esquina derecha): ',
					paragraph_4:
						'Accedemos entonces al formulario de ejecución con dos datos a completar: ',
					paragraph_5: 'Nombre de la ejecución: ',
					paragraph_6: `"Podemos modificar su nombre pinchando en el campo « nombre de la modificación ». El nombre es obligatorio. Encontraremos este nombre en el histórico de las ejecuciones en en lpad, en la rúbrica « carta »."`,
					paragraph_7: 'Fecha de la ejecución:',
					paragraph_8: 'Podemos elegir entre ejecutar: ',
					paragraph_9:
						'inmediatamente: en este caso, tendremos disponibles las ejecuciones en el Ipad cuando hagamos la próxima sincronización (ver apartado siguiente)',
					paragraph_10:
						'en una fecha posterior. Para ello, pinchar en « ejecutar más tarde » y, seguidamente, pinchar en el campo « fecha de la ejecución », escogiendo el día y la hora de la misma. Las modificaciones estarán disponibles en la aplicación en le momento en que se haya realizado una sincronización a partir de la fecha escogida.',
					paragraph_11:
						'Finalmente, pinchar en « validar » para validar el proceso.',
					paragraph_12:
						'Sincronización de las modificaciones de la carta en la aplicación',
					paragraph_13:
						'Una vez haya tenido lugar la ejecución en la carta web, la nueva carta no se activará de manera automática en el Ipad. La podemos, por tanto, ejecutar en cualquier momento del día, sin miedo a sustituir la carta en mitad de un servicio. Las modificaciones únicamente estarán disponibles en la aplicación cuando se lleve a cabo la próxima sincronización de la aplicación.',
					paragraph_14:
						'Se puede sincronizar de dos manera, cuando el Ipad esté conectado a la red Wifi internet: ',
					paragraph_15:
						'De manera « automática » : llevamos a cabo un cierre de servicio, seguido de una apertura de servicio.',
					paragraph_16:
						'De manera « forzada » : Vamos a « Ajustes / Acerca de » y, desde el Ipad principal, pinchamos sobre el botón « Sincro manual ».',
					paragraph_17:
						'En ambos casos, aparecerá un mensaje que nos informará de que se han ejecutado las modificaciones de carta. Podemos pinchar en « Sí » para aceptar, o en « No » para aplicarlas más tarde (ver siguiente sección para activar una nueva carta)',
					paragraph_18:
						'A partir de ese momento, el acceso a la carta en la Carta Web se verá bloqueado momentáneamente. Para acceder de nuevo, hay que volver a hacer un cierre de servicio o una sincronización manual.',
					paragraph_19: 'En resumen: ',
					paragraph_20:
						'Ejecución desde la Carta Web : Se envían las modificaciones a la aplicación, pero no se aplican en el iPad. En el caso de una ejecución inmediata, la carta estará « no sincronizada ». Esta información será visible en el cuaderno recapitulativo de nuestra carta, en la rúbrica « Gestión de carta » (accesible desde el menú superior derecha). En este momento, podemos seguir accediendo a la carta y llevar a cabo otras ejecuciones en ulteriores fechas.',
					paragraph_21:
						'Sincronización en la aplicación desde el iPad principal = Recepción de las modificaciones e interrupción del acceso a la carta en la Carta Web. En este momento, la carta pasa al estadio de « sincronización no efectuada » en la interfaz Web.',
					paragraph_22:
						'Nueva sincronización en la aplicación desde iPad=desbloqueo del acceso a la carta web. En este momento, la carta pasa a estar « sincronizada ».',
					paragraph_23: 'Aplicar las moficiaciones: ',
					paragraph_24: 'Modificación de una carta ya existente: ',
					paragraph_25:
						'Cuando se modifica una carta que está activa en el iPad, una vez se ha ejecutado y se ha realizado la sincronización en el iPad, aparecerá un mensaje indicando que se han llevado a cabo modificaciones en la carta. Pinchando en « Sí » estas modificaciones ser actualizarán inmediatamente en la carta activa.',
					paragraph_26:
						'Si no queremos aplicarlas enseguida, pinchamos en « No ». Podremos aplicarlas más tarde desde el histórico de las cartas (ver « consultar las diferentes cartas en iPad »).',
					paragraph_27: 'Creación de una nueva carta: ',
					paragraph_28:
						'En el caso en que se despliegue una nueva carta, distinta de la que está activa en en iPad, tras la ejecución y la sincronización en el iPad, aparecerá un mensaje indicando que tenemos disponible una nueva carta. Pinchando en el nombre de la carta la podremos recuperar. Sin embargo no estará activada todavía. Tendremos, por tanto, que ir al histórico de las cartas para activarla (ver debajo « Consultar las distintas cartas en iPad »)',
					paragraph_29:
						'Consultar las diferentes cartas en iPady aplicar las modificaciones en espera',
					paragraph_30:
						'Podemos consultar en iPad el listado de nuestras cartas y sus ejecuciones en la rúbrica « Ajustes/Carta » : una pestaña arriba a la derecha informa del nombre de la carta que tengamos activa en este momento. Pincharemos encima para acceder al listado de las diferentes cartas que hayamos desplegado: ',
					paragraph_31:
						'La marca azul debajo del epígrafe de una carta indica cuál es la que tenemos actualmente activa en nuestra aplicación. Para activar una carta diferente, basta con pinchar en la casilla de otra carta y, luego, pinchar en « validar ».',
					paragraph_32:
						'Para consultar el histórico de las diferentes ejecuciones llevadas a cabo en una misma carta, pinchamos en el botón azul « Modificado el XX/XX/XXXX (Ver) ».Accederemos entonces al histórico de las ejecuciones llevadas a cabo en la carta de que se trate.',
					paragraph_33:
						'El círculo azul con un número nos permite estar informadios acerca de las modificaciones importantes que se hayan llevado a cabo.',
					paragraph_34:
						'En el caso de modificaciones de carta en espera, que no hayamos querido aplicar inmediatamente, o en el caso de una carta nueva que no hayamos aceptado inmediatamente, aparecerá un botón naranja debajo de la carta: ',
					paragraph_35:
						'Pinchamos encima para aplicar las modificaciones. En el caso de una carta nueva, deberemos, además, marcar la casilla azul para activar realmente la carta.',
					paragraph_36: 'Eliminar una ejecución programada',
					paragraph_37:
						'En la Carta Web, en la sección « Gestionar las ejecuciones », aparecerán, en orden cronológico, los patch cuya ejecución esté programada para una fecha ulterior. Es posible suprimir un patch futuro siempre y cuando esté programado para más allá de 24 horas. Solo se puede suprimir el último patch programado. Si fueran varios, tendríamos que suprimirlos uno a uno.',
					paragraph_38:
						'Modificar la carta en iPad y sincronizar con la Carta Web.',
					paragraph_39:
						'Si modificamos la carta desde el iPad, esta modificación se reflejará en la Carta Web en el momento de la próxima sincronización manual o cierre del servicio.',
					paragraph_40:
						'Se puede modificar la carta desde la Carta Web y, al mismo tiempo, hacer modificaciones en el iPad. Ambas modificaciones se reagruparán en el momento de la próxima sincronización. Sin embargo, la Carta Web siempre tiene prioridad sobre las modificaciones con respecto a las llevadas a cabo desde el iPad : si modificamos los mismos campos, las modificaciones hechas desde la carta Web sustituirán a las efectuadas desde el iPad.',
					paragraph_41:
						'Por ejemplo : en la Carta web hemos pasado un precio a 10€. Al mismo tiempo, desde el iPad hemos pasado el precio de ese mismo producto a 12€, pero no hemos sincronizado. En el momento de la próxima ejecución seguida de una sincronización, se aplicará en la aplicación el precio que hemos fijado desde la Carta Web (10€)',
				},
			},
			menu: {
				title: 'Menús',
				paragraph:
					'Para añadir un menú, haz clic en “+”. El menú se crea en cuatro etapas. Al final de cada etapa, haz clic en “siguiente” para pasar a la siguiente etapa:',
				denomination: {
					title: '1 / Denominación',
					list: {
						name: {
							title: 'Nombre del menú : ',
							content:
								'(campo obligatorio) nombre del menú utilizado por defecto en la aplicación.',
						},
						short_name: {
							title: 'Abreviatura :  ',
							content:
								'abreviatura del menú utilizada, si la opción ha sido activada, en la pantalla de comandas y/o en las impresiones (en cocina y el ticket de caja).',
						},
						sku: {
							title: 'SKU : ',
							content:
								'si cuentas con varios establecimientos con platos en común, pero con nombres diferentes, introduce un código común para ambos.',
						},
						description: {
							title: 'Descripción : ',
							content:
								'descripción o comentario del menú. Accesible en la pantalla de comandas.',
						},
						active: {
							title: 'Visible : ',
							content:
								'hacer que el menú aparezca (o no) en la lista de productos a la hora de anotar la comanda (por ejemplo, en el caso de que el menú esté temporalmente indisponible o fuera de temporada), sin tener que eliminarlo completamente de la carta.',
						},
						color: {
							title: 'Color del menú : ',
							content: 'color del menú en la pantalla de comandas.',
						},
					},
				},
				prices_exclusions: {
					title: '2 / Precio y exclusiones',
					list: {
						exclusions: {
							title: 'Exclusiones : ',
							content:
								'esta funcionalidad permite elegir en qué salas no aparecerá dicho menú.',
						},
						prices: {
							title: 'Precio :  ',
							content: 'Precio total del menú',
							paragraph:
								'Si has creado categorías de precio (ej.: Happy Hour), en el apartado “Categorías de precio”, estas aparecerán debajo del precio total del menú. Puedes elegir si quieres atribuir o no esta categoría de precio al menú o introducir otro precio.',
						},
					},
				},
				products: {
					title: '3 / Gestión de los productos',
					paragraph_1:
						'Esta etapa te permite definir los diferentes niveles del menú así como los productos atribuidos a cada uno.',
					paragraph_2:
						'Por defecto, hay dos niveles predefinidos: “Entrante” y “Plato”.',
					list: {
						item_1:
							'Para modificar los nombres o atribuirles un icono, haz clic en el lápiz.',
						item_2_1:
							'Para añadir un nivel, haz clic en “Añadir una sección al menú”. Introduce el nombre (ej.: “Postre”) e icono asociados y haz clic en “Añadir”.',
						item_2_2:
							'Puedes elegir que alguno de los niveles del menú sea “obligatorio“ marcando la casilla “Obligatorio”. En la pantalla de comandas, no podrás enviar el menú hasta que no selecciones los productos para dicho nivel.',
						item_2_3:
							'Por ejemplo, para un menú Entrante/Plato o Plato/Postre, con el mismo precio, puedes crear tres niveles de menú (Entrante/Plato/Postre) y hacer que el nivel “Plato” sea obligatorio.',
						item_3:
							'Para eliminar un menú, haz clic en la cruz que aparece al lado del nombre.',
						item_4:
							'Para reorganizar los niveles, selecciónalos y desplázalos hasta el lugar indicado.',
						item_5:
							'Para añadir productos en un nivel, haz clic en “+ producto”. En la ventana que aparece, selecciona los productos que quieras añadir y marca la casilla que aparece a la derecha del producto. El campo “Buscar” te permite encontrar fácilmente un producto por su nombre. También puedes buscar un producto en una de las categorías de la izquierda.',
					},
					paragraph_3:
						'Haz clic en el botón “Confirmar” que aparece en la parte superior derecha del formulario.',
					paragraph_4:
						'El o los productos añadidos aparecerán en el envío correspondiente. Puedes reorganizarlos si lo deseas.',
					paragraph_5:
						'Para ello, haz clic sobre un producto y desplázalo hasta el lugar deseado.',
				},
				validation: {
					title: '4 / Validación',
					content:
						'Esta etapa te permite verificar los datos del menú antes de validarlo.',
				},
			},
			priceCategory: {
				title: 'Categorías de precio (Multiprecio)',
				paragraph:
					'Las categorías de precio sirven para asociar varios precios a un mismo producto. Esta opción puede ser muy útil, por ejemplo, si cuentas con la Happy Hour (hora feliz) en tu establecimiento, o quieres aplicar diferentes precios en función de si el servicio se consume en la barra, terraza…etc. Además, ¡puedes crear tantas categorías como quieras!',
				create: {
					title: 'Crear una categoría de precio',
					paragraph_1:
						'Para crear una categoría, haz clic en “+” y se abrirá un formulario.',
					list: {
						title: 'Completa los siguientes campos: ',
						name: {
							title: 'Nombre : ',
							content:
								'Nombre de la categoría de precio utilizada por defecto en la aplicación.',
						},
						short_name: {
							title: 'Abreviatura : ',
							content:
								'Abreviatura de la categoría de precio, que aparecerá al lado del nombre del producto/menú/complemento en los lugares indicados a continuación, si se han activado las opciones correspondientes:',
							list: {
								item_1:
									'Ver la abreviatura en la comanda: la abreviatura de la categoría aparecerá en la pantalla de comandas.',
								item_2:
									'Ver la abreviatura en el cobro: la abreviatura de la categoría aparecerá en la pantalla de cobro y en los tickets de compra.',
								item_3:
									'Ver la abreviatura en elaboración: la abreviatura de la categoría aparecerá en las órdenes de elaboración.',
							},
						},
						active: {
							title: 'Activado : ',
							content:
								'con esta opción puedes activar o desactivar la categoría de precio para volver a utilizarla posteriormente, sin tener que eliminarla.',
						},
						duration: {
							title: 'Duración : ',
							content:
								' Todo el día/horario: si la categoría de precio debe ser utilizable durante todo el día, deja el botón “Todo el día” activado. Si no, desactívalo para acceder a los horarios y establece un período de validez para la categoría. La fecha de fin puede incluir el día siguiente, por ejemplo de 19:00 de la tarde a 02:00 del día siguiente.',
						},
						days: {
							title: 'Días : ',
							content:
								'permite elegir durante cuántos días estará activa la categoría de precio. Si no quieres añadir excepciones, deja “Todos los días”.',
						},
						floors: {
							title: 'Salas : ',
							content:
								'aquí puedes elegir en qué salas quieres activar la categoría de precio. Si no quieres añadir excepciones, deja “Todas las salas”',
						},
					},
					paragraph_2:
						'Cuando hayas completado todos los campos, haz clic en “Añadir” para crear la categoría de precio.',
				},
				config: {
					title: 'Configurar precios',
					paragraph_1:
						'Puedes configurar los precios de tu categoría de precio de tres maneras :',
					list: {
						item_1: 'Configuración rápida por categoría de productos',
						item_2: 'Copia integral de categoría de precio',
						item_3: 'Configuración manual por productos ',
					},
					paragraph_2_1:
						'Cuando hayas creado tu categoría de precio, aparecerán dos nuevos botones:',
					paragraph_2_2: '« Configuración rápida »',
					paragraph_2_3: ' y ',
					paragraph_2_4: '« Copiar desde » ',
					paragraph_2_5: ' ',
					fast: {
						title: '1 / Configuración rápida : ',
						paragraph_1:
							'El botón “Configuración rápida” sirve para configurar los productos de la carta de una manera rápida (solo los productos).',
						paragraph_2: 'Haz clic para acceder a la configuración rápida.',
						paragraph_3: 'Aquí aparecen todos los complementos de tu carta.',
						paragraph_4:
							'Selecciona uno o varios complementos para modificar el precio aplicado:',
						list: {
							no_changes: {
								title: 'Cancelar: ',
								content:
									'déjalo así si no deseas aplicar la categoría de precio a este complemento.',
							},
							fixed_price: {
								title: 'Precio fijo: : ',
								content:
									'aplica el mismo precio para todos los productos de las categorías seleccionadas.',
							},
							price_plus: {
								title: 'Importe de más: ',
								content:
									'añádele un importe determinado al precio por defecto de cada uno de los complementos',
							},
							price_minus: {
								title: 'Importe reducido : ',
								content:
									'réstale un importe determinado al precio por defecto de cada uno de los complementos.',
							},
							percent_plus: {
								title: '% de más: ',
								content:
									'añádele un determinado porcentaje al precio por defecto de cada uno de los complementos (por ejemplo 50 %) ',
							},
							percent_minus: {
								title: '% reducido: ',
								content:
									'réstale un determinado porcentaje al precio por defecto de cada uno de los complementos.',
							},
							reinit: {
								title: 'Restablecer : ',
								content:
									'esta funcionalidad permite eliminar los precios configurados para los complementos.',
							},
						},
						paragraph_5:
							'Haz clic en uno de los botones, introduce un importe/porcentaje si es necesario y haz clic en “Confirmar”. Puedes establecer diferentes reglas según tus complementos.',
						paragraph_6:
							'Cuando hagas clic en “Confirmar”, aparecerá una ventana para confirmar la configuración de los precios. Haz clic en “Sí” para confirmar.',
						paragraph_7:
							'Para verificar que los precios de la categoría de precios han sido correctamente guardados, haz clic en el icono “i” que aparece en la línea de la categoría de precio. En los productos correspondientes, verás aparecer dos precios: el precio por defecto, así como el precio de la categoría de precio que acabas de configurar.',
					},
					integral: {
						title: '2 / Copia integral :',
						paragraph_1_1: 'Con el botón  ',
						paragraph_1_2: '« Copiar desde » ',
						paragraph_1_3:
							'puedes copiar todos los precios configurados de una categoría de precio (productos, menús, complementos) a otra.',
						paragraph_2:
							'Para ello, y tras haber creado una categoría de precio, haz clic en el botón “Copiar desde”. Aparecerá una ventana con la lista de categorías de precios existentes. Haz clic en la categoría cuyos precios quieras copiar y confirma la copia.',
						paragraph_3:
							'Para verificar que la copia de los precios de la categoría de precio se ha realizado, haz clic en el icono “i” que aparece en la línea de la categoría de precio. En los productos correspondientes, verás aparecer dos precios: el precio por defecto, así como el precio de la categoría de precio que acabas de configurar.',
					},
					by_product: {
						title:
							'3 / Configuración manual por producto / menú / opciones de cocina',
						paragraph_1:
							'Para configurar los precios de una categoría de precio en cada uno de los productos, menús o complementos de tu carta, ve a “Producto” o “Menú”.',
						paragraph_2:
							'La o las categorías de precio que has creado aparecen en la sección “Precio” del formulario de un producto o menú.',
						paragraph_3:
							'Selecciona la categoría de precio que quieres activar e indica su precio.',
						paragraph_4:
							'Para los complementos, ve al apartado “Opciones cocina”. Selecciona la categoría que quieras y haz clic en una de las acciones. La o las categorías de precio que has creado aparecerán en la sección “Precio”.',
					},
				},
			},
			tax: {
				title: 'I.V.A',
				paragraph_1_1: ' ',
				paragraph_1_2: ' ',
				paragraph_1_3: ' ',
				paragraph_1_4: ' ',
				paragraph_1_5: ' ',
				paragraph_1_6: ' ',
				paragraph_1_7: ' ',
				paragraph_2:
					'Puedes modificar/eliminar estas tasas de I.V.A. en la ventana que aparece a la derecha de la pantalla al hacer clic en una tasa de I.V.A.',
				note: {
					title: 'Advertencia: ',
					content_1:
						'si se modifican las tasas, el cambio afectará a todos los productos que estaban sujetos a la tasa modificada. ',
					content_2: '.',
				},
				paragraph_3:
					'Cuando creas un nuevo producto, se le aplica, por defecto, una tipo de I.V.A. del 10 %. Si quieres cambiar la tasa de I.V.A. aplicada por defecto, haz clic en una de las tasas, selecciona “predeterminado” y haz clic en “Añadir”.',
				create: {
					title: 'Crear una nueva tasa de I.V.A.:',
					paragraph_1:
						'Para añadir una nueva tasa de I.V.A., haz clic en “+” y completa los campos que aparecen a la derecha.',
					paragraph_2:
						'Para una misma tasa de I.V.A., puedes configurar diferentes tasas en función del servicio ofrecido (en el establecimiento/terraza/para llevar…etc.)',
				},
				delete: {
					title: 'Eliminar una tasa de I.V.A.:',
					paragraph_1:
						'Solo se pueden eliminar las tasas de I.V.A. que no están activas.',
					paragraph_2:
						'ara eliminar una tasa, tendrás que seleccionar una nueva tasa para los productos que estaban sujetos a dicha tasa, y luego desactivarla (para ello, haz clic sobre la tasa y desmarca la opción “activo”).',
					paragraph_3:
						'Cuando la hayas desactivado, aparecerá un botón rojo en la línea de la tasa. Haz clic en él para eliminarla definitivamente.',
					note: {
						title: 'Advertencia : ',
						content:
							'las tasas de I.V.A. que aparecen “por defecto” en la aplicación no se pueden eliminar.',
					},
					paragraph_4:
						'Si tienes alguna duda sobre el tipo de I.V.A. que debes aplicar en determinados productos, te recomendamos que te pongas en contacto con tu contable para que resuelva tu duda.',
				},
			},
			master: {
				title: 'Master Multi-établissements',
				presentation: {
					title: 'Présentation des fonctionnalités',
					p1_1:
						'Gestion Multi-Établissements à distance - Interface web / Connexion',
					p1_2:
						'Depuis un navigateur web, il est nécessaire de se connecter à l’adresse menu.laddition.com et d’utiliser les identifiants correspondants au compte « Master Carte» de vos établissements.',
					p2_1: 'Modifications centralisées, par zone ou par établissement',
					p2_2:
						'Selon les règles définies (voir après) il est possible de gérer les modifications de carte en centralisé (mode « siège») par zone ou par site',
					p3_1: 'Programmation en avance des modifications de carte',
					p3_2:
						'Il est possible d’utiliser le calendrier disponible afin de programmer des modifications de carte. Il est possible de créer ou retirer des produit en avance, de changer les prix',
					p3_3: 'ATTENTION',
					p3_4:
						'Si vous changez l’un de ces champs à une date ultérieure, le changement s’appliquera le jour où vous l’avez changé et déployé. En effet, il est recommandé de créer un nouveau produit plutôt que changer son nom. D’un point de vu statistiques des ventes, cela sera bénéfique car le produit en question pourra être sélectionné (dans les produits invisibles ou supprimés).',
					p3_5: 'Nom du produit + Nom court',
					p3_6: 'SKU',
					p3_7: 'Couleur',
					p3_8: "Lieu d'envoi",
					p4_1: 'Reporting optimisé pour l’analyse des ventes',
					p4_2:
						'En plus des rapports existants par établissement, certains rapports d’analyse sont disponibles au niveau « multi-établissements » comme par exemple : ',
					p4_3: 'Comparaison d’établissements',
					p4_5: 'Analyse produits groupée (Mise en place de SKU obligatoire)',
					p4_6: 'Synthèse des paiements ',
					p4_7: 'Etc...',
					p4_8:
						'Pour plus d’informations sur le reporting, voir le manuel utilisateur du reporting.',
				},
				synchro: {
					title: 'LES REGLES DE SYNCHRONISATION',
					p1:
						'Toutes les caractéristiques marquées d’une coche sur les captures ci-après peuvent être centralisées ou personnalisables par site. Lorsqu’il y a une flèche à double sens, cela signifie que l’ordre des éléments peut également  être centralisé ou personnalisable par site.',
					p2: 'LES PRODUITS',
					p3: 'LES CATEGORIES DE PRODUITS',
					p4: 'LES CATÉGORIES DE SUPPLÉMENTS ET SUPPLEMENTS',
					p5: 'LES SUPPLEMENTS ASSIGNES AUX PRODUITS',
				},
				deploy: {
					title: 'LES DEPLOIEMENTS',
					p1_1: 'CHOIX DU SITE OU DE LA ZONE CONCERNES',
					p1_2:
						'Le déploiement peut se faire par zone ou par site. Il faut s’assurer du site ou de la zone choisie avant de déployer en cliquant sur l’icône « bonhomme » sur le menu à gauche de l’écran et choisissez la zone ou le site sur lequel vous souhaitez déployer. S’affiche alors une nouvelle icône dont la siginification est détaillée ci-après.',
					p1_3:
						'Une fois les modifications effectuées, il faut aller dans l’onglet « déploiement » en bas du menu sur la gauche de l’écran. Les changements sont conservés automatiquement même sans déployer si vous décidez de ne pas envoyer les modifications sur les caisses immédiatement',
					p1_3_1: '-> Mode siège (pas de déploiement possible)',
					p1_3_2: '-> Zone',
					p1_3_3: '-> Site',
					p1_4:
						'Une fois sur la page de déploiement, vous trouverez les informations suivantes : ',
					p1_4_1: '1 - La date concernée pour les changements',
					p1_4_2: '2 - Le site ou la zone concernés ',
					p1_4_3:
						'3 - Le nom personnalisable des modifications (initialement la date du jour pré-remplie)',
					p1_4_4: '4 - Le bouton de déploiement',
					p1_5: 'ATTENTION :',
					p1_5_1:
						'Bouton bleu = Des modifications n’ont pas encore été déployées',
					p1_5_2: 'Bouton gris = Pas de modification à déployer',
					p1_6:
						'Après avoir cliqué sur le bouton « déployer», vous vous retrouverez sur la page récapitulative du déploiement effectué. Vous pouvez sélectionner un nouveau site ou une nouvelle zone pour poursuivre les déploiements si nécessaire.',
					p2:
						'COMMENT LES ETABLISSEMENTS PEUVENT RECUPERER LES MODIFICATIONS DE CARTE APRES DEPLOIEMENT',
					p2_1: 'RECUPERATION « MANUELLE» DES MODIFICATIONS',
					p2_2:
						"Après le déploiement de la carte, chaque établissement doit synchroniser l'iPad principal (soit de manière automatique lors de l'ouverture/clôture d'un service, soit de manière « forcée » : aller dans « Réglages / A propos » depuis l’iPad et cliquer sur le bouton « Synchro manuelle »)",
					p2_3:
						"L'application alerte que des modifications peuvent être apportées sur la carte, dans la fenêtre il faut cliquer sur le bouton « Oui» afin de récupérer la carte master qui sera commune à tous les établissements.",
					p2_4:
						'Lorsque les modifications sont récupérées, l’application indique que « Les modifications ont été effectuées avec succès».',
					p3: 'RECUPERATION « AUTOMATIQUE» DES MODIFICATIONS',
					p3_1:
						'Avec un paramétrage spécifique, il est possible de mettre en place la récupération automatique des modifications de carte à partir du moment que la tablette maître est connectée à Internet, que le service est ouvert et que l’application est en premier plan.',
					p3_2:
						'Pour cela il est nécessaire de faire parvenir un mail à integration@laddition.com',
					p3_3:
						'A ce moment, lorsque le déploiement est effectué, une notification s’affiche sur l’iPad Maître pour indiquer que « des modifications de carte ont été appliquées » (il suffit de faire glisser la notification vers la gauche pour la supprimer).',
				},
			},
		},
	},
	save: 'Registro',
	confirmation: 'Confirmación',
	validate: 'Validar',
	cancel: 'Cancelar',
	remove: 'Eliminar',
	edit: 'Editar',
	close: 'Cerrar',
	notification: {
		title: 'Notificaciones',
		show: 'Mostrar',
		empty: 'No hay notificaciones',
		error: {
			product:
				'Se ha producido un error durante la %{method} del producto: %{name}',
			category:
				'Se ha producido un error durante la %{method} de la categoría: %{name}',
			priceCategory:
				'Se ha producido un error durante la %{method} de la categoría de precios: %{name}',
		},
		toast: {
			warning: 'Por favor, consulta tus notificaciones',
			error: 'Se ha producido un error',
			forbidden: 'Acceso denegado',
			success: 'Acción realizada con éxito',
			unauthorized: 'No tiene suficientes derechos para realizar esta acción.',
		},
		type: {
			WARNING: 'Atención',
			ERROR: 'Error',
			INFO: 'Info',
			SUCCESS: 'Exito',
		},
		no_detail: 'No hay mas detalles',
	},
	method: {
		PATCH: 'actualización',
		PUT: 'actualización',
		POST: 'creación',
		DELETE: 'supresión',
	},
	no_access:
		'No tienes acceso para acceder a la herramienta de gestión de la carta online',
	no_access_reject:
		'No tiene acceso a la herramienta de administración de menú web, pronto se implementará un patch. Por favor sincronice sus iPads. Tendrá acceso a esta herramienta nuevamente después del cierre del próximo servicio.',
	no_access_explained:
		'No tienes acceso para acceder a la herramienta de gestión de la carta online. Puede que ya hayas configurado tu carta. Ponte en contacto con el soporte técnico para más información.',
	no_access_tax_rate_empty:
		'No tienes acceso para acceder a la herramienta de gestión de la carta online. Por favor, contacte el soporte técnico indicando el código de error "601"',
	no_access_version:
		'No tienes acceso para acceder a la herramienta de gestión de la carta online. Por favor, actualice todos sus dispositivos',
	no_access_need_activation:
		'No tienes acceso para acceder a la herramienta de gestión de la carta online. Por favor, activar tu aplicación en un iPad',
	no_access_in_synchro:
		'Se está llevando a cabo una sincronización en el carte online. Por favor, inténtelo de nuevo en unos momentos',
	no_access_maintenance:
		'La aplicación está en mantenimiento... nos disculpamos por las molestias.',
	no_access_restriction: 'No tiene acceso a esta sección',
	no_access_multi_window: 'Se ha abierto una sesión desde otro navegador,',
	no_access_multi_window_2:
		'Haga clic en el botón de abajo para generar una nueva sesión.',
	no_access_multi_window_button: 'Activar la sesión',
	multi_session_master_mono_use:
		'El establecimiento gestor ha abierto este sesión. Para acceder a su tarjeta, póngase en contacto con él.',
	wrong_url: 'página no encontrada',
	browser_compatibility: {
		version_title: 'Navegador obsoleto',
		version_label:
			'Para beneficiar de una óptima experiencia, utiliza la versión más reciente de Google Chrome o Firefox',
		chrome: 'Google Chrome',
		firefox: 'Mozilla Firefox',
		screen_size_title: 'Pantalla demasiado pequeña',
		screen_size_label:
			' Para beneficiar de una óptima experiencia, utiliza una pantalla más grande.',
	},
	icon: {
		search: 'Buscsar un icono',
	},
	title: {
		denomination: 'Denominación',
		informations: 'Informaciones',
		price: 'Precio',
		supplements: 'Complementos',
		exclusions: 'Exclusiones',
		color: 'Color',
		icon: 'Icono',
		floors: 'Salas',
		service: 'Carta',
	},
	offline: 'Advertencia, no hay conexión a internet',
	tutorial: {
		interactTuto: 'Tutorial interactivo',
		noDemo: 'Saltar tutorial',
		yesDemo: 'Ver tutorial',
		nextStep: 'Siguiente etapa',
		leftDemo: 'Cerrar tutorial',
		back: 'Volver',
		initial_popup: {
			title: '¿Necesitas ayuda?',
			text: 'Le gustaría leer o asistencia online',
			tutorial: 'Tutorial',
			help: 'Asistencia online',
			no_thank: 'No, gracias',
		},
		topics: {
			endDemoTopics: 'Fin del tutorial sobre las rúbricas',
			step1: {
				content: 'Aquí puedes ver las diferentes rúbricas de tu carta.',
				title: 'Rúbricas',
			},
			step2: {
				content:
					"Por ejemplo: haciendo clic en 'categorías' podrás organizar la carta por platos o bebidas. Aquí podrás gestionar las categorías de tus productos.",
				title: 'Categorías',
			},
			step3: {
				content:
					'Si tienes alguna duda sobre las rúbricas, consulta la sección de ayuda',
				title: 'Ayuda',
			},
		},
		category: {
			endDemoCategory: 'Fin del tutorial sobre las categorías',
			step1: {
				content:
					'Aquí puedes crear categorías en las que podrás reagrupar los productos para organizar mejor tu carta.',
				title: 'Categorías',
			},
			step2: {
				content: 'Añade un nombre para tu nueva categoría',
				title: 'Elegir nombre',
			},
			step3: {
				content: 'Elige un color para identificar la categoría fácilmente',
				title: 'Elegir color',
			},
			step4: {
				content: ' Elige un icono para identificar la categoría fácilmente',
				title: 'Elegir icono',
			},
			step5: {
				content: 'Elige en qué salas no aparecerá esta categoría',
				title: 'Exclusión en sala/s',
			},
			step6: {
				content: 'Guardar categoría',
				title: 'Guardar',
			},
			step7: {
				content:
					'Puedes cambiar el orden de tus categorías deslizándolas hasta el lugar deseado',
				title: 'Cambiar orden',
			},
			step8: {
				content:
					'Puedes editar tu categoría haciendo clic en ella, y eliminarla haciendo clic en el icono de la papelera',
				title: 'Editar/Eliminar',
			},
		},
		product: {
			endDemoProduct: 'Fin del tutorial sobre los productos',
			step1: {
				content:
					'Aquí puedes crear productos de dos maneras: configuración rápida o configuración estándar',
				title: 'Productos',
			},
			step2: {
				content: 'Haz clic en el botón ⊕ para añadir productos',
				title: 'Añadir productos',
			},
			step3: {
				content: 'Añadir un producto rápidamente',
				title: 'Configuración rápida',
			},
			step4: {
				content:
					'Introduce todos los detalles del producto (nombre, precio, categoría, etc.) haciendo clic en él. Comienza por el campo "nombre" para que aparezcan los demás.',
				title: 'Configuración rápida',
			},
			step5: {
				content:
					'Con el primer botón puedes editar el producto, con el segundo puedes eliminarlo',
				title: 'Editar/Eliminar',
			},
			step6: {
				content:
					'Utiliza la barra de búsqueda y los filtros para encontrar rápidamente un producto en concreto',
				title: 'Búsqueda/Filtros',
			},
			step7: {
				content:
					'No te preocupes si cometes algún error. Podrás volver a la página deseada utilizando los botones “Anterior/Próxima”',
				title: 'Anterior/Próxima',
			},
			step8: {
				content: 'Haz clic en el botón ⊕, para añadir productos',
				title: 'Añadir producto',
			},
			step9: {
				content: 'Aquí puedes añadir un producto',
				title: 'Configuración estándar',
			},
			step10: {
				content: 'Añade el nombre de tu producto',
				title: 'Nombre del producto',
			},
			step11: {
				content: 'Añade la abreviatura, el SKU y la descripción de tu producto',
				title: 'Información adicional (opcional)',
			},
			step12: {
				content:
					'Información relativa al precio de tu producto (precio al kilo, precio durante la Happy Hour, etc.)',
				title: 'Precio',
			},
			step13: {
				content:
					'La información general de tu producto (I.V.A., Tipo, Categoría, etc.)',
				title: 'Información general',
			},
			step14: {
				content:
					'Añade opciones de cocina. Para ello, tendrás que haber creado algunas en el apartado "Opciones de cocina"',
				title: 'Opciones de cocina',
			},
			step15: {
				content:
					'Elige un color para diferenciarlo de otros productos o asociarlo con una categoría',
				title: 'Elegir color',
			},
			step16: {
				content: 'Elige en qué salas no aparecerá este producto',
				title: 'Exclusión en sala/s',
			},
			step17: {
				content:
					'Guarda la categoría creada. Debes añadirle, al menos, un nombre, un precio, una tasa y tipo de I.V.A. para poder guardar el producto',
				title: 'Guardar',
			},
			step18: {
				content:
					'Tu producto se ha guardado correctamente. Puedes crear otro rápidamente pulsando Shift + Enter',
				title: 'Recapitulación',
			},
			step19: {
				content:
					'En la parte inferior derecha de la pantalla encontrarás un botón para duplicar los datos.',
				title: 'Duplicar datos',
			},
			step20: {
				content:
					'Encontrarás todas las categorías de productos en el panel de la izquierda. Selecciona una.',
				title: 'Categorías de productos',
			},
			step21: {
				content:
					'Aquí podrás añadir productos en cada categoría. Para ello, desliza los productos hasta la categoría deseada.',
				title: 'Añadir productos en una categoría',
			},
		},
		productMenu: {
			endDemoProductMenu: 'Fin del tutorial sobre los menús',
			step1: {
				content:
					'Esta rúbrica permite gestionar los menús (Crear, Editar, Eliminar)',
				title: 'Menú',
			},
			step2: {
				content: 'Haz clic en el botón ⊕, para crear un menú',
				title: 'Crear menú',
			},
			step3: {
				content: 'Un menú se crea en 4 etapas',
				title: 'Menú por secciones',
			},
			step4: {
				content:
					'Añade un nombre para el menú. También puedes añadir información adicional',
				title: 'Nombre del menú',
			},
			step5: {
				content: 'Elige un color para el menú',
				title: 'Color del menú',
			},
			step6: {
				content:
					'Fin de la primera etapa. Ahora vas a eligir el precio del menú.',
				title: 'Fin de la primera etapa',
			},
			step7: {
				content: 'Elige en qué salas no aparecerá este menú',
				title: 'Exclusión en sala/s',
			},
			step8: {
				content: 'Elige el precio de tu menú',
				title: 'Precio del menú',
			},
			step9: {
				content:
					'Fin de la segunda etapa. Ahora vas a elegir los platos que componen el menú.',
				title: 'Fin de la segunda etapa',
			},
			step10: {
				content:
					'Aquí puedes crear las secciones de tu menú (Predeterminado: Entrante/Plato). Puedes elegir los platos en función de las secciones.',
				title: 'Gestiona las secciones del menú',
			},
			step11: {
				content: 'Añadir sección (Ej.: Postre)',
				title: 'Nueva sección',
			},
			step12: {
				content:
					'Las secciones de un menú pueden tener un nombre, un icono y varios complementos',
				title: 'Descripción de la sección del menú',
			},
			step13: {
				content: 'Elige el nombre de la sección del menú',
				title: 'Nombre de la sección',
			},
			step14: {
				content: 'Elige los complementos de esta sección',
				title: 'Complementos',
			},
			step15: {
				content: 'Elegir icono',
				title: 'Icono',
			},
			step16: {
				content:
					'Guarda la sección del menú para elegir los platos que la componen',
				title: 'Guardar sección del menú',
			},
			step17: {
				content: 'Elige qué platos componen esta sección del menú',
				title: 'Elegir productos',
			},
			step18: {
				content:
					'Haz clic en "+ Producto", para añadir productos en la sección "Entrante".',
				title: 'Añadir producto',
			},
			step19: {
				content:
					'En el panel de la izquierda puedes ver tus categorías de productos.',
				title: 'Categorías de productos',
			},
			step20: {
				content:
					'En el panel de la derecha puedes ver todos los productos de la categoría seleccionada. Para añadir un producto, marca la casilla. ',
				title: 'Elegir plato',
			},
			step21: {
				content: 'Guardar cambios',
				title: 'Guardar',
			},
			step22: {
				content:
					'Recapitulación de las secciones de tu menú y los productos que las componen. Para trasladar un producto de una sección a otra, solo tienes que deslizarlo',
				title: 'Recapitulación',
			},
			step23: {
				content:
					'Haz clic en un producto para modificarlo rápidamente. Elige su color y precio y decide en qué salas quieres que aparezca',
				title: 'Modificar un producto rápidamente',
			},
			step24: {
				content:
					'Fin de la tercera etapa. Si has completado todas las secciones del menú, a continuación verás la recapitulación del menú',
				title: 'Fin de la tercera etapa',
			},
			step25: {
				content: 'Recapitulación del contenido de tu menú',
				title: 'Recapitulación del menú',
			},
			step26: {
				content: 'Valida tu menú para poder utilizarlo',
				title: 'Validación',
			},
			step27: {
				content: 'Aquí puedes ver todos tus menús',
				title: 'Recapitulación de los menús',
			},
		},
		optionsKitchen: {
			endDemoOptionKitchen: 'Fin del tutorial sobre las opciones de cocina',
			step1: {
				content:
					'Aquí puedes crear diferentes opciones de cocina (complementos), reagrupadas por categorías (ej.: Cocción: Sellada, Muy hecha…)',
				title: 'Opciones de cocina',
			},
			step2: {
				content: 'Añade una categoría de complemento',
				title: 'Añadir categoría',
			},
			step3: {
				content: 'Completa los diferentes campos',
				title: 'Descripción',
			},
			step4: {
				content: 'Añade el nombre de tu categoría (ej.: Cocción)',
				title: 'Nombre de la categoría',
			},
			step5: {
				content: 'La información de la categoría te permite personalizarla',
				title: 'Información de la categoría',
			},
			step6: {
				content: 'Elige el icono de la categoría',
				title: 'Icono',
			},
			step7: {
				content: 'Elige el color de la categoría',
				title: 'Color',
			},
			step8: {
				content: 'Elige en qué salas no aparecerá esta categoría',
				title: 'Exclusión en sala',
			},
			step9: {
				content: 'Guardar categoría',
				title: 'Guardar',
			},
			step10: {
				content:
					'Puedes reorganizar las categorías de complementos deslizándolas hasta el lugar deseado. Para eliminar un elemento, haz clic en el icono de la papelera. Haz clic en la categoría para añadir complementos',
				title: 'Recapitulación',
			},
			step11: {
				content:
					'Añade complementos o espacios (para diferenciar las diferentes opciones) en tu categoría de complementos',
				title: 'Añadir complemento',
			},
			step12: {
				content: 'Especifica el nombre del complemento (ej.: Con patatas)',
				title: 'Nombre del complemento',
			},
			step13: {
				content: 'Elige el precio del complemento (ej.: Con patatas +2€)',
				title: 'Precio',
			},
			step14: {
				content: 'Elige el icono de este complemento',
				title: 'Icono',
			},
			step15: {
				content: 'Elige en qué salas no aparecerá este complemento',
				title: 'Exclusión en sala',
			},
			step16: {
				content: 'Guardar complemento',
				title: 'Guardar',
			},
			step17: {
				content: 'Haz clic en este icono para asignar productos',
				title: 'Asignar productos',
			},
			step18: {
				content:
					'Elige en qué productos quieres aplicar estas opciones de cocina haciendo clic en ellos o en el botón “Asignar productos”',
				title: 'Asignar productos',
			},
			step19: {
				content: 'Guardar productos',
				title: 'Guardar',
			},
			step20: {
				content:
					'Recapitulación de tus categorías y los complementos que las componen',
				title: 'Recapitulación',
			},
		},
		tax: {
			endDemoTax: 'Fin del tutorial sobre las tasas',
			step1: {
				content:
					'Aquí puedes añadir otras tasas. Por defecto, hay 4 tasas predeterminadas. Puedes modificarlas o añadir otras',
				title: 'I.V.A',
			},
			step2: {
				content:
					'A la izquierda puedes ver tus tasas de I.V.A. (predeterminadas + creadas)',
				title: 'Recapitulación',
			},
			step3: {
				content: 'Añade el nombre de la tasa de I.V.A.',
				title: 'Nombre',
			},
			step4: {
				content:
					'Puedes aplicar una tasa de I.V.A. diferente si el producto es para llevar',
				title: 'Otras tasas de I.V.A.',
			},
			step5: {
				content:
					'Si eliges una tasa de I.V.A. predeterminada, esta se aplicará automáticamente en cada nuevo producto',
				title: 'Tasa de I.V.A. predeterminada',
			},
			step6: {
				content: 'Guarda la tasa de I.V.A.',
				title: 'Guardar',
			},
			step7: {
				content: 'Puedes añadir una tasa haciendo clic aquí',
				title: 'Nueva tasa',
			},
		},
		priceCategory: {
			endDemoPriceCategory: 'Fin del tutorial sobre las categorías de precio',
			step1: {
				content:
					'Aquí puedes crear categorías de precio (ej.: precios para la Happy Hour, pedidos para llevar, consumición en terraza, etc.)',
				title: 'Categoría de precio',
			},
			step2: {
				content: 'Crea una categoría',
				title: 'Nueva categoría',
			},
			step3: {
				content: 'En este formulario hay opciones configuradas por defecto',
				title: 'Categoría de precio',
			},
			step4: {
				content: 'Añade el nombre de la categoría',
				title: 'Nombre',
			},
			step5: {
				content: 'Añade una categoría (opcional)',
				title: 'Abreviatura',
			},
			step6: {
				content: 'Elige cuándo utilizar la abreviatura de la categoría',
				title: 'Uso de la abreviatura',
			},
			step7: {
				content:
					'Por defecto, tu categoría está disponible durante todo el día. Puedes desactivar esta opción y establecer el período de validez de la categoría (ej.: Happy Hour de 16:00 a 19:00)',
				title: 'Disponibilidad/horas',
			},
			step8: {
				content:
					'Por defecto, tu categoría está disponible durante toda la semana. Puedes desactivar esta opción y establecer el período de validez de la categoría',
				title: 'Disponibilidad/días',
			},
			step9: {
				content: ` Elige en qué salas no aparecerá esta categoría de precio`,
				title: 'Exclusión en sala/s',
			},
			step10: {
				content: `Guardar categoría de precio`,
				title: 'Guardar',
			},
			step11: {
				content: `Tu categoría de precio se ha guardado correctamente. Haz clic en una categoría para modificarla o cambia el orden deslizándola hasta el lugar deseado`,
				title: 'Recapitulación de las categorías de precio',
			},
			step12: {
				content: `Las asociaciones permiten ver qué productos/menús/opciones de cocina se han añadido en esta categoría de precio`,
				title: 'Asociaciones',
			},
			step13: {
				content: `Haz clic en una categoría de precio para modificar la información y asociarla a una categoría de productos`,
				title: 'Asociar a una categoría de productos',
			},
			step14: {
				content: `Elige en qué categorías de productos quieres aplicar estos precios`,
				title: 'Categoría de precio',
			},
		},
	},
	dateZonePicker: {
		title_master: 'Administrar la fecha y el modo de edición',
		title_mono: 'Gestionar la fecha de salida',
		activated: 'Activado',
		select: 'Seleccione',
		date: {
			title: 'Calendario',
			description: 'Aplicar los cambios en la carta del',
		},
		nbCompanies: '%{nb} Establecimiento.s',
		management: {
			title: 'Visualización de la carta',
			master: 'Cuartel general',
			master_description: 'Modificaciones centralizadas',
			zone: 'Zonas',
			zone_description: 'Cambios por zona',
			company: 'Establecimientos',
			company_description: 'Modificaciones por un establecimiento',
		},
		prodiverChanges: {
			title: 'Cambios externos',
			text: 'Hay %{n} parches hechos a partir de una aplicación de terceros.',
			button: 'Aplicar parches',
		},
	},
};
